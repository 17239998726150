/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;
    .navbar-header{
        background: $topbar-header;
    }
    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {

            color: $topbar-text!important;
        }
    }
}


/*******************
/*General Elements
*******************/

a {
    color: $themecolor-alt;

    &.link,
    &:hover,
    &:focus {
        color: darken($themecolor-alt, 10%);
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}
.text-themecolor{
    color:$themecolor!important;
}
.profile-tab,
.customtab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}

[type="radio"]:checked + label:after {
  border: 2px solid $themecolor-dark;
  background-color: $themecolor-dark;
}

/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar, .card-no-border .sidebar-nav {
  background: $sidebar;
}
.mini-sidebar .sidebar-nav {
  background: transparent;
}
.sidebar-nav {
    background: $sidebar;
    ul {
        li {
            a {
                color: $sidebar-text;
                &.active,
                &:hover {
                    color: $themecolor-alt;
                }
                &.active {
                    color: $themecolor-alt;
                }
            }
            &.nav-small-cap {
                color: $muted;
            }
        }
    }
    > ul > li {
        &.active > a, &.active:hover > a {

            color: $sidebar-text;
            background: transparent !important;
            border: 1px solid $themecolor-alt !important;
            i {
                color: $sidebar-text;
            }
            &:after{
                border-color:$sidebar-text;
            }
        }
    }
    > ul > li > a {

        &.active,
        &:hover {

            i {
                color: $themecolor-alt;
            }
        }
        i {
            color: $sidebar-icons;
        }
        &.active {
            font-weight: 400;
            background: $sidebar-text;
            color: $themecolor;
        }
    }
}

@media #{$small-and-up} {
    .mini-sidebar .sidebar-nav #sidebarnav > li {
        & > ul {
            background: $sidebar-footer;
        }
        &:hover > a {
            background: $themecolor-dark !important;
            border-color: $themecolor !important;
        }
    }
}

.card-no-border .sidebar-footer {
    background: $sidebar-footer;

    a {
        color: $sidebar-text;
    }
}


.page-wrapper {
    background-color: $bodycolor;
}
