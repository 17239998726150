@charset "UTF-8";
/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_variable.scss.
*/
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers 
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@import url("https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700");
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
@import url("https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700");
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}
.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. Posts
 *	7. Widgets
 *	8. Custom Templates
 */
/******************* 
Global Styles 
*******************/
* {
  outline: none;
}

body {
  background: #231f20;
  font-family: "Inter", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #67757c;
  font-weight: 300;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

a:hover, a:focus {
  text-decoration: none;
}

a.link {
  color: #2f2a2a;
}
a.link:hover, a.link:focus {
  color: #f7921e;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before, html body .mdi-set {
  line-height: initial;
}

/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2f2a2a;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}
html body .db {
  display: block;
}

.no-wrap td, .no-wrap th {
  white-space: nowrap;
}

/*******************
Blockquote
*******************/
html body blockquote {
  border-left: 5px solid #f7921e;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/
html body .p-0 {
  padding: 0px;
}
html body .p-10 {
  padding: 10px;
}
html body .p-20 {
  padding: 20px;
}
html body .p-30 {
  padding: 30px;
}
html body .p-l-0 {
  padding-left: 0px;
}
html body .p-l-10 {
  padding-left: 10px;
}
html body .p-l-20 {
  padding-left: 20px;
}
html body .p-r-0 {
  padding-right: 0px;
}
html body .p-r-10 {
  padding-right: 10px;
}
html body .p-r-20 {
  padding-right: 20px;
}
html body .p-r-30 {
  padding-right: 30px;
}
html body .p-r-40 {
  padding-right: 40px;
}
html body .p-t-0 {
  padding-top: 0px;
}
html body .p-t-10 {
  padding-top: 10px;
}
html body .p-t-20 {
  padding-top: 20px;
}
html body .p-t-30 {
  padding-top: 30px;
}
html body .p-b-0 {
  padding-bottom: 0px;
}
html body .p-b-5 {
  padding-bottom: 5px;
}
html body .p-b-10 {
  padding-bottom: 10px;
}
html body .p-b-20 {
  padding-bottom: 20px;
}
html body .p-b-30 {
  padding-bottom: 30px;
}
html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/
html body .m-0 {
  margin: 0px;
}
html body .m-l-5 {
  margin-left: 5px;
}
html body .m-l-10 {
  margin-left: 10px;
}
html body .m-l-15 {
  margin-left: 15px;
}
html body .m-l-20 {
  margin-left: 20px;
}
html body .m-l-30 {
  margin-left: 30px;
}
html body .m-l-40 {
  margin-left: 40px;
}
html body .m-r-5 {
  margin-right: 5px;
}
html body .m-r-10 {
  margin-right: 10px;
}
html body .m-r-15 {
  margin-right: 15px;
}
html body .m-r-20 {
  margin-right: 20px;
}
html body .m-r-30 {
  margin-right: 30px;
}
html body .m-r-40 {
  margin-right: 40px;
}
html body .m-t-0 {
  margin-top: 0px;
}
html body .m-t-5 {
  margin-top: 5px;
}
html body .m-t-10 {
  margin-top: 10px;
}
html body .m-t-15 {
  margin-top: 15px;
}
html body .m-t-20 {
  margin-top: 20px;
}
html body .m-t-30 {
  margin-top: 30px;
}
html body .m-t-40 {
  margin-top: 40px;
}
html body .m-b-0 {
  margin-bottom: 0px;
}
html body .m-b-5 {
  margin-bottom: 5px;
}
html body .m-b-10 {
  margin-bottom: 10px;
}
html body .m-b-15 {
  margin-bottom: 15px;
}
html body .m-b-20 {
  margin-bottom: 20px;
}
html body .m-b-30 {
  margin-bottom: 30px;
}
html body .m-b-40 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/
html body .vt {
  vertical-align: top;
}
html body .vm {
  vertical-align: middle;
}
html body .vb {
  vertical-align: bottom;
}

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/
html body .font-bold {
  font-weight: 700;
}
html body .font-normal {
  font-weight: normal;
}
html body .font-light {
  font-weight: 300;
}
html body .font-medium {
  font-weight: 500;
}
html body .font-16 {
  font-size: 16px;
}
html body .font-14 {
  font-size: 14px;
}
html body .font-10 {
  font-size: 10px;
}
html body .font-18 {
  font-size: 18px;
}
html body .font-20 {
  font-size: 20px;
}

/*******************
Border
*******************/
html body .b-0 {
  border: none;
}
html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Thumb size
*******************/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

/*******************
Text Colors
*******************/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-muted {
  color: #9e9c97 !important;
}

.text-warning {
  color: #DCAA34 !important;
}

.text-success {
  color: #21CB61 !important;
}

.text-info {
  color: #1DA6F8 !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

html body .text-blue {
  color: #02bec9;
}
html body .text-purple {
  color: #7460ee;
}
html body .text-primary {
  color: #fdb714;
}
html body .text-megna {
  color: #00897b;
}
html body .text-dark {
  color: #67757c;
}
html body .text-themecolor {
  color: #f7921e;
}

/*******************
Background Colors
*******************/
.bg-primary {
  background-color: #fdb714 !important;
}

.bg-success {
  background-color: #21CB61 !important;
}

.bg-info {
  background-color: #1DA6F8 !important;
}

.bg-warning {
  background-color: #DCAA34 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

html body .bg-megna {
  background-color: #00897b;
}
html body .bg-theme {
  background-color: #f7921e;
}
html body .bg-inverse {
  background-color: #2f3d4a;
}
html body .bg-purple {
  background-color: #7460ee;
}
html body .bg-light-primary {
  background-color: #fff5de;
}
html body .bg-light-success {
  background-color: #c3f5d6;
}
html body .bg-light-info {
  background-color: #e3f4fe;
}
html body .bg-light-extra {
  background-color: #ebf3f5;
}
html body .bg-light-warning {
  background-color: #faf3e2;
}
html body .bg-light-danger {
  background-color: #f6cdd1;
}
html body .bg-light-inverse {
  background-color: #f2f4f7;
}
html body .bg-light {
  background-color: #f2f4f8;
}
html body .bg-white {
  background-color: #ffffff;
}

/*******************
Rounds
*******************/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #1DA6F8;
}
.round img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: #1DA6F8;
}

.round.round-warning {
  background: #DCAA34;
}

.round.round-danger {
  background: #dc3545;
}

.round.round-success {
  background: #21CB61;
}

.round.round-primary {
  background: #fdb714;
}

/*******************
Labels
*******************/
.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #00897b;
}

.label-success {
  background-color: #21CB61;
}

.label-info {
  background-color: #1DA6F8;
}

.label-warning {
  background-color: #DCAA34;
}

.label-danger {
  background-color: #dc3545;
}

.label-megna {
  background-color: #00897b;
}

.label-primary {
  background-color: #fdb714;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #2f3d4a;
}

.label-default {
  background-color: #f2f4f8;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #c3f5d6;
  color: #21CB61;
}

.label-light-info {
  background-color: #e3f4fe;
  color: #1DA6F8;
}

.label-light-warning {
  background-color: #faf3e2;
  color: #DCAA34;
}

.label-light-danger {
  background-color: #f6cdd1;
  color: #dc3545;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #00897b;
}

.label-light-primary {
  background-color: #fff5de;
  color: #fdb714;
}

.label-light-inverse {
  background-color: #f2f4f7;
  color: #2f3d4a;
}

/*******************
Pagination
*******************/
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
  color: #2f2a2a;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #f2f4f8;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f7921e;
  border-color: #f7921e;
}

.pager li > a,
.pager li > span {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #2f2a2a;
}

/*******************
Table Cell
*******************/
.table-box {
  display: table;
  width: 100%;
}

.table.no-border tbody td {
  border: 0px;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.table td, .table th {
  border-color: #f3f1f1;
}

.table thead th, .table th {
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: #f2f4f8;
}

.v-middle td, .v-middle th {
  vertical-align: middle;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*******************
Wave Effects
*******************/
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}

html body .waves-notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}
.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-block {
  display: block;
}

/*Badge*/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #21CB61;
}

.badge-info {
  background-color: #1DA6F8;
}

.badge-primary {
  background-color: #fdb714;
}

.badge-warning {
  background-color: #DCAA34;
  color: #ffffff;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #2f3d4a;
}

/*******************
Buttons
******************/
.btn {
  padding: 7px 12px;
  font-size: 14px;
  cursor: pointer;
}

.btn-group label {
  color: #ffffff !important;
  margin-bottom: 0px;
}
.btn-group label.btn-secondary {
  color: #67757c !important;
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-md {
  padding: 12px 55px;
  font-size: 16px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  font-size: 18px;
  padding: 11px 15px !important;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.button-list button, .button-list a {
  margin: 5px 12px 5px 0;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}
.btn-rounded.btn-lg {
  padding: 0.75rem 1.5rem;
}
.btn-rounded.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}
.btn-rounded.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}
.btn-rounded.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

.btn-secondary,
.btn-secondary.disabled {
  background: #ffffff;
  border: 1px solid #727b84;
  color: #727b84;
}
.btn-secondary:hover,
.btn-secondary.disabled:hover {
  background: #727b84;
  opacity: 0.7;
  border: 1px solid #727b84;
}
.btn-secondary.active, .btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:focus {
  background: #727b84;
  box-shadow: none;
  color: #ffffff;
}

.btn-primary,
.btn-primary.disabled {
  background: #fdb714;
  border: 1px solid #fdb714;
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  transition: 0.2s ease-in;
}
.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #fdb714;
  box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
  border: 1px solid #fdb714;
}
.btn-primary.active, .btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:focus {
  background: #f5ac02;
  box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #f7921e;
  color: #ffffff;
  border: 1px solid #f7921e;
}
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #f7921e;
  opacity: 0.7;
  border: 1px solid #f7921e;
}
.btn-themecolor.active, .btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
  background: #fdb714;
}

.btn-success,
.btn-success.disabled {
  background: #21CB61;
  border: 1px solid #21CB61;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
}
.btn-success:hover,
.btn-success.disabled:hover {
  background: #21CB61;
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  border: 1px solid #21CB61;
}
.btn-success.active, .btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:focus {
  background: #1db557;
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
}

.btn-info,
.btn-info.disabled {
  background: #1DA6F8;
  border: 1px solid #1DA6F8;
  box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  transition: 0.2s ease-in;
}
.btn-info:hover,
.btn-info.disabled:hover {
  background: #1DA6F8 !important;
  border: 1px solid #1DA6F8 !important;
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}
.btn-info.active, .btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:focus {
  background: #089bf4 #028ee1 !important;
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2) !important;
}

.btn-warning,
.btn-warning.disabled {
  background: #DCAA34;
  color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  border: 1px solid #DCAA34;
  transition: 0.2s ease-in;
}
.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #DCAA34;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
  border: 1px solid #DCAA34;
}
.btn-warning.active, .btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:focus {
  background: #d29e24;
  box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
}

.btn-danger,
.btn-danger.disabled {
  background: #dc3545;
  border: 1px solid #dc3545;
  box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  transition: 0.2s ease-in;
}
.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #dc3545;
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
  border: 1px solid #dc3545;
}
.btn-danger.active, .btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:focus {
  background: #d32535;
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}

.btn-inverse,
.btn-inverse.disabled {
  background: #2f3d4a;
  border: 1px solid #2f3d4a;
  color: #ffffff;
}
.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #2f3d4a;
  opacity: 0.7;
  color: #ffffff;
  border: 1px solid #2f3d4a;
}
.btn-inverse.active, .btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:focus {
  background: #25303a;
  color: #ffffff;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}
.btn-red:hover,
.btn-red.disabled:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
}
.btn-red.active, .btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:focus {
  background: #d32535;
}

.btn-outline-secondary {
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
}
.btn-outline-secondary.active, .btn-outline-secondary:focus {
  box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
}

.btn-outline-primary {
  color: #fdb714;
  background-color: #ffffff;
  border-color: #fdb714;
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
  background: #fdb714;
  box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
  color: #ffffff;
  border-color: #fdb714;
}
.btn-outline-primary.active, .btn-outline-primary:focus {
  box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
}

.btn-outline-success {
  color: #21CB61;
  background-color: transparent;
  border-color: #21CB61;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus {
  background: #21CB61;
  border-color: #21CB61;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
}
.btn-outline-success.active, .btn-outline-success:focus {
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
}

.btn-outline-info {
  color: #1DA6F8;
  background-color: transparent;
  border-color: #1DA6F8;
  box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
  background: #1DA6F8;
  border-color: #1DA6F8;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}
.btn-outline-info.active, .btn-outline-info:focus {
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}

.btn-outline-warning {
  color: #DCAA34;
  background-color: transparent;
  border-color: #DCAA34;
  box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
  background: #DCAA34;
  border-color: #DCAA34;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
}
.btn-outline-warning.active, .btn-outline-warning:focus {
  box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545;
  box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
  transition: 0.2s ease-in;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
  background: #dc3545;
  border-color: #dc3545;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}
.btn-outline-danger.active, .btn-outline-danger:focus {
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a;
}
.btn-outline-red:hover, .btn-outline-red:focus, .btn-outline-red.focus {
  background: #fb3a3a;
  border-color: #fb3a3a;
  color: #ffffff;
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}
.btn-outline-red.active, .btn-outline-red:focus {
  box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}

.btn-outline-inverse {
  color: #2f3d4a;
  background-color: transparent;
  border-color: #2f3d4a;
}
.btn-outline-inverse:hover, .btn-outline-inverse:focus, .btn-outline-inverse.focus {
  background: #2f3d4a;
  border-color: #2f3d4a;
  color: #ffffff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #f5ac02;
  border: 1px solid #f5ac02;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #1db557;
  border: 1px solid #1db557;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #089bf4 #028ee1;
  border: 1px solid #089bf4 #028ee1;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #d29e24;
  border: 1px solid #d29e24;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #d32535;
  border: 1px solid #d32535;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #25303a;
  border: 1px solid #25303a;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.button-box .btn {
  margin: 0 8px 8px 0px;
}

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px;
}

.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
}

.btn-twitter {
  color: #ffffff;
  background-color: #55acee;
}

.btn-linkedin {
  color: #ffffff;
  background-color: #007bb6;
}

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
}

.btn-googleplus {
  color: #ffffff;
  background-color: #dd4b39;
}

.btn-instagram {
  color: #ffffff;
  background-color: #3f729b;
}

.btn-pinterest {
  color: #ffffff;
  background-color: #cb2027;
}

.btn-dropbox {
  color: #ffffff;
  background-color: #007ee5;
}

.btn-flickr {
  color: #ffffff;
  background-color: #ff0084;
}

.btn-tumblr {
  color: #ffffff;
  background-color: #32506d;
}

.btn-skype {
  color: #ffffff;
  background-color: #00aff0;
}

.btn-youtube {
  color: #ffffff;
  background-color: #bb0000;
}

.btn-github {
  color: #ffffff;
  background-color: #171515;
}

/*******************
Notify
*******************/
.notify {
  position: relative;
  top: -25px;
  right: -7px;
}
.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid #dc3545;
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.notify .point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #dc3545;
  position: absolute;
  right: 6px;
  top: -10px;
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
/*******************
File Upload 
******************/
.fileupload {
  overflow: hidden;
  position: relative;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/*******************
Megamenu
******************/
.mega-dropdown {
  position: static;
  width: 100%;
}
.mega-dropdown .dropdown-menu {
  width: 100%;
  padding: 30px;
  margin-top: 0px;
}
.mega-dropdown ul {
  padding: 0px;
}
.mega-dropdown ul li {
  list-style: none;
}
.mega-dropdown .carousel-item .container {
  padding: 0px;
}
.mega-dropdown .nav-accordion .card {
  margin-bottom: 1px;
}
.mega-dropdown .nav-accordion .card-header {
  background: #ffffff;
}
.mega-dropdown .nav-accordion .card-header h5 {
  margin: 0px;
}
.mega-dropdown .nav-accordion .card-header h5 a {
  text-decoration: none;
  color: #67757c;
}

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}
ul.list-style-none li {
  list-style: none;
}
ul.list-style-none li a {
  color: #67757c;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}
ul.list-style-none li a:hover {
  color: #f7921e;
}

/*******************
dropdown-item
******************/
.dropdown-item {
  padding: 8px 1rem;
  color: #67757c;
}

/*******************
Custom-select
******************/
.custom-select {
  background: url(../../assets/images/custom-select.png) right 0.75rem center no-repeat;
}

/*******************
textarea
******************/
textarea {
  resize: none;
}

/*******************
Form-control
******************/
.form-control {
  color: #67757c;
  min-height: 38px;
  display: initial;
}

.form-control-sm {
  min-height: 20px;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 0.7;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #21CB61;
}

form label {
  font-weight: 400;
}

.form-group {
  margin-bottom: 25px;
}

.form-horizontal label {
  margin-bottom: 0px;
}

.form-control-static {
  padding-top: 0px;
}

.form-bordered .form-group {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  padding-bottom: 20px;
}

/*******************
Layouts
******************/
/*Card-noborders*/
.card-no-border .card {
  border-color: #d7dfe3;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
.card-no-border .sidebar-footer {
  background: #f2f6f8;
}
.card-no-border .sidebar-nav > ul > li > a.active {
  background: #ffffff;
}

.card-no-border .shadow-none {
  box-shadow: none;
}

.card-outline-danger, .card-outline-info, .card-outline-warning, .card-outline-success, .card-outline-primary {
  background: #ffffff;
}

.card-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}

.card .card-header {
  background: #ffffff;
  border-bottom: 0px;
}

/*******************/
/*widgets -app*/
/*******************/
.css-bar:after {
  z-index: 1;
}

.css-bar > i {
  z-index: 10;
}

/*******************/
/*single column*/
/*******************/
.single-column .left-sidebar {
  display: none;
}
.single-column .page-wrapper {
  margin-left: 0px;
}

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

/***********************
 9/02/2018 (New Update)
***********************/
.progress {
  height: auto;
}

.card-group {
  margin-bottom: 30px;
}

.tablesaw [type=checkbox]:not(:checked), .tablesaw [type=checkbox]:checked,
.fixed-table-container [type=checkbox]:not(:checked),
.fixed-table-container [type=checkbox]:checked {
  position: relative;
  left: 0;
  opacity: 1;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For all pages
 ============================================================== */
#main-wrapper {
  width: 100%;
}

.boxed #main-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}
.boxed #main-wrapper .sidebar-footer {
  position: absolute;
}
.boxed #main-wrapper .footer {
  display: none;
}

.page-wrapper {
  background: #f9f8f8;
  padding-bottom: 60px;
}

.container-fluid {
  padding: 0 30px 25px 30px;
}

/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  z-index: 50;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
}
.topbar .top-navbar {
  min-height: 70px;
  padding: 0px 15px 0 0;
}
.topbar .top-navbar .dropdown-toggle::after {
  display: none;
}
.topbar .top-navbar .navbar-header {
  line-height: 65px;
  text-align: center;
}
.topbar .top-navbar .navbar-header .navbar-brand {
  margin-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: none;
}
.topbar .top-navbar .navbar-header .navbar-brand b {
  line-height: 70px;
  display: inline-block;
}
.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 19px;
  line-height: 50px;
}
.topbar .top-navbar .navbar-nav > .nav-item.show {
  background: rgba(0, 0, 0, 0.05);
}
.topbar .profile-pic {
  width: 30px;
  border-radius: 100%;
}
.topbar .dropdown-menu {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  border-color: rgba(120, 130, 140, 0.13);
}
.topbar .dropdown-menu .dropdown-item {
  padding: 7px 1.5rem;
}
.topbar ul.dropdown-user {
  padding: 0px;
  width: 270px;
}
.topbar ul.dropdown-user li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.topbar ul.dropdown-user li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, 0.13);
}
.topbar ul.dropdown-user li .dw-user-box {
  padding: 10px 15px;
}
.topbar ul.dropdown-user li .dw-user-box .u-img {
  width: 80px;
  display: inline-block;
  vertical-align: top;
}
.topbar ul.dropdown-user li .dw-user-box .u-img img {
  width: 100%;
  border-radius: 5px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text {
  display: inline-block;
  padding-left: 10px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text h4 {
  margin: 0px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text p {
  margin-bottom: 2px;
  font-size: 14px;
}
.topbar ul.dropdown-user li .dw-user-box .u-text .btn {
  color: #ffffff;
  padding: 5px 10px;
  display: inline-block;
}
.topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
  background: #d32535;
}
.topbar ul.dropdown-user li a {
  padding: 9px 15px;
  display: block;
  color: #67757c;
}
.topbar ul.dropdown-user li a:hover {
  background: #f2f4f8;
  color: #f7921e;
  text-decoration: none;
}

.search-box .app-search {
  position: absolute;
  margin: 0px;
  display: block;
  z-index: 110;
  width: 100%;
  top: -1px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  display: none;
  left: 0px;
}
.search-box .app-search input {
  width: 100%;
  padding: 25px 40px 25px 20px;
  border-radius: 0px;
  font-size: 17px;
  transition: 0.5s ease-in;
  height: 70px;
}
.search-box .app-search .srh-btn {
  position: absolute;
  top: 23px;
  cursor: pointer;
  background: #ffffff;
  width: 15px;
  height: 15px;
  right: 20px;
  font-size: 14px;
}

.mini-sidebar .top-navbar .navbar-header {
  width: 60px;
  text-align: center;
}

.logo-center .top-navbar .navbar-header {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*******************
 Breadcrumb and page title
*******************/
.page-titles {
  background: #ffffff;
  margin: 0 -30px 30px;
  padding: 15px;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
.page-titles h3 {
  margin-bottom: 0px;
  margin-top: 8px;
}
.page-titles .breadcrumb {
  padding: 0px;
  background: transparent;
  font-size: 14px;
}
.page-titles .breadcrumb li {
  margin-top: 0px;
  margin-bottom: 0px;
}
.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\e649";
  font-family: themify;
  color: #a6b7bf;
  font-size: 11px;
}
.page-titles .breadcrumb .breadcrumb-item.active {
  color: #9e9c97;
}

/*******************
 Right side toggle
*******************/
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.right-side-toggle {
  position: relative;
}

.right-side-toggle i {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  transition-property: transform;
  transition-duration: 1s;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 18px;
  left: 18px;
}

.right-sidebar {
  position: fixed;
  right: -240px;
  width: 240px;
  display: none;
  z-index: 1100;
  background: #ffffff;
  top: 0px;
  padding-bottom: 20px;
  height: 100%;
  box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.right-sidebar .rpanel-title {
  display: block;
  padding: 24px 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  background: #f7921e;
}
.right-sidebar .rpanel-title span {
  float: right;
  cursor: pointer;
  font-size: 11px;
}
.right-sidebar .rpanel-title span:hover {
  color: #ffffff;
}
.right-sidebar .r-panel-body {
  padding: 20px;
}
.right-sidebar .r-panel-body ul {
  margin: 0px;
  padding: 0px;
}
.right-sidebar .r-panel-body ul li {
  list-style: none;
  padding: 5px 0;
}

.shw-rside {
  right: 0px;
  width: 240px;
  display: block;
}

.chatonline img {
  margin-right: 10px;
  float: left;
  width: 30px;
}
.chatonline li a {
  padding: 13px 0;
  float: left;
  width: 100%;
}
.chatonline li a span {
  color: #67757c;
}
.chatonline li a span small {
  display: block;
  font-size: 10px;
}

/*******************
 Right side toggle
*******************/
ul#themecolors {
  display: block;
}
ul#themecolors li {
  display: inline-block;
}
ul#themecolors li:first-child {
  display: block;
}
ul#themecolors li a {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 5px;
  color: transparent;
  position: relative;
}
ul#themecolors li a.working:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  text-align: center;
}

.default-theme {
  background: #9e9c97;
}

.green-theme {
  background: #21CB61;
}

.yellow-theme {
  background: #DCAA34;
}

.red-theme {
  background: #dc3545;
}

.blue-theme {
  background: #1DA6F8;
}

.purple-theme {
  background: #7460ee;
}

.megna-theme {
  background: #00897b;
}

.default-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #9e9c97 23%, #9e9c97 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #9e9c97 23%, #9e9c97 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #9e9c97 23%, #9e9c97 99%);
}

.green-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #21CB61 23%, #21CB61 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #00c292 23%, #21CB61 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #21CB61 23%, #21CB61 99%);
}

.yellow-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
}

.blue-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #1DA6F8 23%, #1DA6F8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #1DA6F8 23%, #1DA6F8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #1DA6F8 23%, #1DA6F8 99%);
}

.purple-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #7460ee 23%, #7460ee 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #7460ee 23%, #7460ee 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #7460ee 23%, #7460ee 99%);
}

.megna-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #00897b 23%, #00897b 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #00897b 23%, #00897b 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #00897b 23%, #00897b 99%);
}

.red-dark-theme {
  background: #2f2a2a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #2f2a2a 0%, #2f2a2a 23%, #dc3545 23%, #dc3545 99%);
}

/*******************
 page title
*******************/
.page-titles {
  padding-bottom: 20px;
}

/*******************
 Footer
*******************/
.footer {
  bottom: 0;
  color: #67757c;
  left: 0px;
  padding: 17px 15px;
  position: absolute;
  right: 0;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  background: #ffffff;
}

/*******************
 Card title
*******************/
.card {
  margin-bottom: 30px;
}
.card .card-subtitle {
  font-weight: 300;
  margin-bottom: 15px;
  color: #9e9c97;
}

.card-inverse .card-blockquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65);
}

.card-success {
  background: #21CB61;
  border-color: #21CB61;
}

.card-danger {
  background: #dc3545;
  border-color: #dc3545;
}

.card-warning {
  background: #DCAA34;
  border-color: #DCAA34;
}

.card-info {
  background: #1DA6F8;
  border-color: #1DA6F8;
}

.card-primary {
  background: #fdb714;
  border-color: #fdb714;
}

.card-dark {
  background: #2f3d4a;
  border-color: #2f3d4a;
}

.card-megna {
  background: #00897b;
  border-color: #00897b;
}

/*==============================================================
 Buttons page
 ============================================================== */
.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}
.btn.active .text-active {
  display: inline-block;
}
.btn.active .text {
  display: none;
}

/*==============================================================
 Cards page
 ============================================================== */
.card-actions {
  float: right;
}
.card-actions a {
  cursor: pointer;
  color: #67757c;
  opacity: 0.7;
  padding-left: 7px;
  font-size: 13px;
}
.card-actions a:hover {
  opacity: 1;
}

.card-columns .card {
  margin-bottom: 20px;
}

.collapsing {
  -webkit-transition: height 0.08s ease;
  transition: height 0.08s ease;
}

.card-info {
  background: #1DA6F8;
  border-color: #1DA6F8;
}

.card-primary {
  background: #fdb714;
  border-color: #fdb714;
}

.card-outline-info {
  border-color: #1DA6F8;
}
.card-outline-info .card-header {
  background: #1DA6F8;
  border-color: #1DA6F8;
}

.card-outline-inverse {
  border-color: #2f3d4a;
}
.card-outline-inverse .card-header {
  background: #2f3d4a;
  border-color: #2f3d4a;
}

.card-outline-warning {
  border-color: #DCAA34;
}
.card-outline-warning .card-header {
  background: #DCAA34;
  border-color: #DCAA34;
}

.card-outline-success {
  border-color: #21CB61;
}
.card-outline-success .card-header {
  background: #21CB61;
  border-color: #21CB61;
}

.card-outline-danger {
  border-color: #dc3545;
}
.card-outline-danger .card-header {
  background: #dc3545;
  border-color: #dc3545;
}

.card-outline-primary {
  border-color: #fdb714;
}
.card-outline-primary .card-header {
  background: #fdb714;
  border-color: #fdb714;
}

/*==============================================================
Breadcrumb
 ============================================================== */
.bc-colored .breadcrumb-item,
.bc-colored .breadcrumb-item a {
  color: #ffffff;
}
.bc-colored .breadcrumb-item.active,
.bc-colored .breadcrumb-item a.active {
  opacity: 0.7;
}
.bc-colored .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb {
  margin-bottom: 0px;
}

/*==============================================================
 Ui-bootstrap
 ============================================================== */
ul.list-icons {
  margin: 0px;
  padding: 0px;
}
ul.list-icons li {
  list-style: none;
  line-height: 30px;
  margin: 5px 0;
  transition: 0.2s ease-in;
}
ul.list-icons li a {
  color: #67757c;
}
ul.list-icons li a:hover {
  color: #f7921e;
}
ul.list-icons li i {
  font-size: 13px;
  padding-right: 8px;
}

ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}

ul.two-part {
  margin: 0px;
}
ul.two-part li {
  width: 48.8%;
}

/*Accordion*/
html body .accordion .card {
  margin-bottom: 0px;
}

/*==============================================================
 flot chart
 ============================================================== */
.flot-chart {
  display: block;
  height: 400px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

html body .jqstooltip,
html body .flotTip {
  width: auto !important;
  height: auto !important;
  background: #2f2a2a;
  color: #ffffff;
  padding: 5px 10px;
}

/*==============================================================
Easy pie chart
 ============================================================== */
.chart {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.pie-chart > span {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.chart > span > img {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 60%;
  height: 60%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.percent {
  display: inline-block;
  line-height: 100px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #2f2a2a;
}
.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

/*==============================================================
Dashboard1 chart
 ============================================================== */
.ct-charts {
  position: relative;
}

.amp-pxl {
  position: relative;
}
.amp-pxl .ct-series-a .ct-bar {
  stroke: #1DA6F8;
}
.amp-pxl .ct-series-b .ct-bar {
  stroke: #21CB61;
}

.c3-chart-arcs-title, .c3-legend-item {
  font-family: "Inter", sans-serif;
  fill: #67757c;
}

html body #visitor .c3-chart-arcs-title {
  font-size: 18px;
  fill: #9e9c97;
}

.stylish-table thead th {
  font-weight: 400;
  color: #9e9c97;
  border: 0px;
  border-bottom: 1px;
}
.stylish-table tbody tr {
  border-left: 4px solid #ffffff;
}
.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-left: 4px solid #f7921e;
}
.stylish-table tbody td {
  vertical-align: middle;
}
.stylish-table tbody td h6 {
  font-weight: 500;
  margin-bottom: 0px;
  white-space: nowrap;
}
.stylish-table tbody td small {
  line-height: 12px;
  white-space: nowrap;
}

/*==============================================================
Dashboard2 chart
 ============================================================== */
.campaign {
  height: 280px;
}
.campaign .ct-series-a .ct-area {
  fill-opacity: 0.2;
  fill: url(#gradient);
}
.campaign .ct-series-a .ct-line, .campaign .ct-series-a .ct-point {
  stroke: #21CB61;
  stroke-width: 2px;
}
.campaign .ct-series-b .ct-area {
  fill: #1DA6F8;
  fill-opacity: 0.1;
}
.campaign .ct-series-b .ct-line, .campaign .ct-series-b .ct-point {
  stroke: #1DA6F8;
  stroke-width: 2px;
}
.campaign .ct-series-a .ct-point, .campaign .ct-series-b .ct-point {
  stroke-width: 6px;
}

.campaign2 .ct-series-a .ct-area {
  fill-opacity: 0.2;
  fill: url(#gradient);
}
.campaign2 .ct-series-a .ct-line, .campaign2 .ct-series-a .ct-point {
  stroke: #21CB61;
  stroke-width: 2px;
}
.campaign2 .ct-series-b .ct-area {
  fill: #1DA6F8;
  fill-opacity: 0.1;
}
.campaign2 .ct-series-b .ct-line, .campaign2 .ct-series-b .ct-point {
  stroke: #1DA6F8;
  stroke-width: 2px;
}
.campaign2 .ct-series-a .ct-point, .campaign2 .ct-series-b .ct-point {
  stroke-width: 6px;
}

.usage .ct-series-a .ct-line {
  stroke-width: 3px;
  stroke: rgba(255, 255, 255, 0.5);
}

/*==============================================================
Dashboard3 chart
 ============================================================== */
.total-sales {
  position: relative;
}
.total-sales .chartist-tooltip {
  background: #2f3d4a;
}
.total-sales .ct-series-a .ct-bar {
  stroke: #1DA6F8;
}
.total-sales .ct-series-b .ct-bar {
  stroke: #21CB61;
}
.total-sales .ct-series-c .ct-bar {
  stroke: #dc3545;
}

.ct-chart {
  position: relative;
}
.ct-chart .ct-series-a .ct-slice-donut {
  stroke: #21CB61;
}
.ct-chart .ct-series-b .ct-slice-donut {
  stroke: #f2f4f8;
}
.ct-chart .ct-series-c .ct-slice-donut {
  stroke: #1DA6F8;
}

#visitfromworld path.jvectormap-region.jvectormap-element {
  stroke-width: 1px;
  stroke: #9e9c97;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  background: #9e9c97;
}

.browser td {
  vertical-align: middle;
  padding-left: 0px;
}

#calendar .fc-today-button {
  display: none;
}

.calendar-events {
  padding: 8px 10px;
  border: 1px solid #ffffff;
  cursor: move;
}
.calendar-events:hover {
  border: 1px dashed rgba(120, 130, 140, 0.13);
}
.calendar-events i {
  margin-right: 8px;
}

/*==============================================================
Dashboard4 chart
 ============================================================== */
.total-revenue4 {
  position: relative;
}
.total-revenue4 .ct-series-a .ct-line {
  stroke: #1DA6F8;
  stroke-width: 1px;
}
.total-revenue4 .ct-series-a .ct-point {
  stroke: #1DA6F8;
  stroke-width: 5px;
}
.total-revenue4 .ct-series-b .ct-line {
  stroke: #21CB61;
  stroke-width: 1px;
}
.total-revenue4 .ct-series-b .ct-point {
  stroke: #21CB61;
  stroke-width: 5px;
}
.total-revenue4 .ct-series-a .ct-area {
  fill: #1DA6F8;
  fill-opacity: 0.2;
}
.total-revenue4 .ct-series-b .ct-area {
  fill: #21CB61;
  fill-opacity: 0.2;
}

/*==============================================================
Dashboard6 chart
 ============================================================== */
.product-overview.table tbody tr td {
  vertical-align: middle;
}

/*==============================================================
Widget-data
 ============================================================== */
.sparkchart {
  margin-bottom: -2px;
}

/*==============================================================
File upload
 ============================================================== */
.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
  border-radius: 0px;
}

.fileinput .input-group-addon {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background: #ffffff;
  margin-bottom: 1px;
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

/*==============================================================
widget-chart-page product review
 ============================================================== */
.product-review {
  margin: 0px;
  padding: 25px;
}
.product-review li {
  display: block;
  padding: 20px 0;
  list-style: none;
}
.product-review li .font,
.product-review li span {
  display: inline-block;
  margin-left: 10px;
}

.social-profile {
  text-align: center;
  background: rgba(7, 10, 43, 0.8);
}

.profile-tab li a.nav-link, .customtab li a.nav-link {
  border: 0px;
  padding: 15px 20px;
  color: #67757c;
}
.profile-tab li a.nav-link.active, .customtab li a.nav-link.active {
  border-bottom: 2px solid #f7921e;
  color: #f7921e;
}
.profile-tab li a.nav-link:hover, .customtab li a.nav-link:hover {
  color: #f7921e;
}

/*==============================================================
Form addons page
============================================================== */
/*Bootstrap select*/
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.select2-container--default .select2-selection--single {
  border-color: #d9d9d9;
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 33px;
}

.input-form .btn {
  padding: 10px 12px;
}

/*==============================================================
Form Material page
 ============================================================== */
/*Material inputs*/
.form-material .form-group {
  overflow: hidden;
}

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
}

.form-material .form-control, .form-material .form-control.focus, .form-material .form-control:focus {
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
}

.form-material .form-control.focus, .form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: 0.3s;
}

.form-control-line .form-group {
  overflow: hidden;
}

.form-control-line .form-control {
  border: 0px;
  border-radius: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #d9d9d9;
}
.form-control-line .form-control:focus {
  border-bottom: 1px solid #f7921e;
}

/*******************/
/*Floating Label*/
/*******************/
.floating-labels .form-group {
  position: relative;
}

.floating-labels .form-control {
  padding: 10px 10px 10px 0;
  display: block;
  border: none;
  font-family: "Inter", sans-serif;
  border-radius: 0px;
  border-bottom: 1px solid #d9d9d9;
}
.floating-labels .form-control:focus {
  box-shadow: none;
}

.floating-labels select.form-control > option {
  font-size: 14px;
}

.floating-labels .has-error .form-control {
  border-bottom: 1px solid #dc3545;
}

.floating-labels .has-warning .form-control {
  border-bottom: 1px solid #DCAA34;
}

.floating-labels .has-success .form-control {
  border-bottom: 1px solid #21CB61;
}

.floating-labels .form-control:focus {
  outline: none;
  border: none;
}

.floating-labels label {
  color: #67757c;
  position: absolute;
  cursor: auto;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-labels .focused label {
  top: -20px;
  font-size: 12px;
  color: #2f2a2a;
}

.floating-labels .bar {
  position: relative;
  display: block;
}

.floating-labels .bar:before,
.floating-labels .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #f7921e;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-labels .bar:before {
  left: 50%;
}

.floating-labels .bar:after {
  right: 50%;
}

.floating-labels .form-control:focus ~ .bar:before,
.floating-labels .form-control:focus ~ .bar:after {
  width: 50%;
}

.floating-labels .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.floating-labels .input-lg ~ label,
.floating-labels .input-lg {
  font-size: 24px;
}

.floating-labels .input-sm ~ label,
.floating-labels .input-sm {
  font-size: 16px;
}

.has-warning .bar:before,
.has-warning .bar:after {
  background: #DCAA34;
}

.has-success .bar:before,
.has-success .bar:after {
  background: #21CB61;
}

.has-error .bar:before,
.has-error .bar:after {
  background: #dc3545;
}

.has-warning .form-control:focus ~ label,
.has-warning .form-control:valid ~ label {
  color: #DCAA34;
}

.has-success .form-control:focus ~ label,
.has-success .form-control:valid ~ label {
  color: #21CB61;
}

.has-error .form-control:focus ~ label,
.has-error .form-control:valid ~ label {
  color: #dc3545;
}

.has-feedback label ~ .t-0 {
  top: 0;
}

.form-group.error input, .form-group.error select, .form-group.error textarea {
  border: 1px solid #dc3545;
}

.form-group.validate input, .form-group.validate select, .form-group.validate textarea {
  border: 1px solid #21CB61;
}

.form-group.error .help-block ul {
  padding: 0px;
  color: #dc3545;
}
.form-group.error .help-block ul li {
  list-style: none;
}

.form-group.issue .help-block ul {
  padding: 0px;
  color: #DCAA34;
}
.form-group.issue .help-block ul li {
  list-style: none;
}

/*******************
Pagination
******************/
.pagination-circle li.active a {
  background: #21CB61;
}
.pagination-circle li a {
  width: 40px;
  height: 40px;
  background: #f2f4f8;
  border: 0px;
  text-align: center;
  border-radius: 100%;
}
.pagination-circle li a:first-child, .pagination-circle li a:last-child {
  border-radius: 100%;
}
.pagination-circle li a:hover {
  background: #21CB61;
  color: #ffffff;
}
.pagination-circle li.disabled a {
  background: #f2f4f8;
  color: rgba(120, 130, 140, 0.13);
}

/*******************
Form Dropzone
******************/
.dropzone {
  border: 1px dashed #d9d9d9;
}
.dropzone .dz-message {
  padding: 5% 0;
  margin: 0px;
}

/*******************
Form Pickers
******************/
.asColorPicker-dropdown {
  max-width: 260px;
}

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-decoration: none;
}

table th {
  font-weight: 400;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #f7921e;
}

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background: #f7921e;
  color: #ffffff;
}

.datepicker td, .datepicker th {
  padding: 5px 10px;
}

/*******************
Form icheck
******************/
.icolors,
.icheck-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.icolors > li {
  padding: 0;
  margin: 2px;
  float: left;
  display: inline-block;
  height: 30px;
  width: 30px;
  background: #2f2a2a;
  text-align: center;
}

.icolors > li.active:after {
  content: "✓";
  color: #ffffff;
  line-height: 30px;
}

.icolors > li:first-child {
  margin-left: 0;
}

.icolors > li.orange {
  background: #dc3545;
}

.icolors > li.yellow {
  background: #DCAA34;
}

.icolors > li.info {
  background: #1DA6F8;
}

.icolors > li.green {
  background: #21CB61;
}

.icolors > li.red {
  background: #fb3a3a;
}

.icolors > li.purple {
  background: #7460ee;
}

.icolors > li.blue {
  background: #02bec9;
}

.icheck-list {
  float: left;
  padding-right: 50px;
  padding-top: 10px;
}

.icheck-list li {
  padding-bottom: 5px;
}

.icheck-list li label {
  padding-left: 10px;
}

/*******************
Form summernote
******************/
.note-popover, .note-icon-caret {
  display: none;
}

.note-editor.note-frame {
  border: 1px solid #d9d9d9;
}
.note-editor.note-frame .panel-heading {
  padding: 6px 10px 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.label {
  display: inline-block;
}

/*******************
Table-Layout
******************/
.table thead th, .table th {
  border: 0px;
}

.color-table.primary-table thead th {
  background-color: #fdb714;
  color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f2f4f8;
}

.color-table.success-table thead th {
  background-color: #21CB61;
  color: #ffffff;
}

.color-table.info-table thead th {
  background-color: #1DA6F8;
  color: #ffffff;
}

.color-table.warning-table thead th {
  background-color: #DCAA34;
  color: #ffffff;
}

.color-table.danger-table thead th {
  background-color: #dc3545;
  color: #ffffff;
}

.color-table.inverse-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-table.dark-table thead th {
  background-color: #2f2a2a;
  color: #ffffff;
}

.color-table.red-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-table.purple-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-table.muted-table thead th {
  background-color: #9e9c97;
  color: #ffffff;
}

.color-bordered-table.primary-bordered-table {
  border: 2px solid #fdb714;
}
.color-bordered-table.primary-bordered-table thead th {
  background-color: #fdb714;
  color: #ffffff;
}

.color-bordered-table.success-bordered-table {
  border: 2px solid #21CB61;
}
.color-bordered-table.success-bordered-table thead th {
  background-color: #21CB61;
  color: #ffffff;
}

.color-bordered-table.info-bordered-table {
  border: 2px solid #1DA6F8;
}
.color-bordered-table.info-bordered-table thead th {
  background-color: #1DA6F8;
  color: #ffffff;
}

.color-bordered-table.warning-bordered-table {
  border: 2px solid #DCAA34;
}
.color-bordered-table.warning-bordered-table thead th {
  background-color: #DCAA34;
  color: #ffffff;
}

.color-bordered-table.danger-bordered-table {
  border: 2px solid #dc3545;
}
.color-bordered-table.danger-bordered-table thead th {
  background-color: #dc3545;
  color: #ffffff;
}

.color-bordered-table.inverse-bordered-table {
  border: 2px solid #2f3d4a;
}
.color-bordered-table.inverse-bordered-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-bordered-table.dark-bordered-table {
  border: 2px solid #2f2a2a;
}
.color-bordered-table.dark-bordered-table thead th {
  background-color: #2f2a2a;
  color: #ffffff;
}

.color-bordered-table.red-bordered-table {
  border: 2px solid #fb3a3a;
}
.color-bordered-table.red-bordered-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-bordered-table.purple-bordered-table {
  border: 2px solid #7460ee;
}
.color-bordered-table.purple-bordered-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-bordered-table.muted-bordered-table {
  border: 2px solid #9e9c97;
}
.color-bordered-table.muted-bordered-table thead th {
  background-color: #9e9c97;
  color: #ffffff;
}

.full-color-table.full-primary-table {
  background-color: #fff5de;
}
.full-color-table.full-primary-table thead th {
  background-color: #fdb714;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-primary-table tbody td {
  border: 0;
}
.full-color-table.full-primary-table tr:hover {
  background-color: #fdb714;
  color: #ffffff;
}

.full-color-table.full-success-table {
  background-color: #c3f5d6;
}
.full-color-table.full-success-table thead th {
  background-color: #21CB61;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-success-table tbody td {
  border: 0;
}
.full-color-table.full-success-table tr:hover {
  background-color: #21CB61;
  color: #ffffff;
}

.full-color-table.full-info-table {
  background-color: #e3f4fe;
}
.full-color-table.full-info-table thead th {
  background-color: #1DA6F8;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-info-table tbody td {
  border: 0;
}
.full-color-table.full-info-table tr:hover {
  background-color: #1DA6F8;
  color: #ffffff;
}

.full-color-table.full-warning-table {
  background-color: #faf3e2;
}
.full-color-table.full-warning-table thead th {
  background-color: #DCAA34;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-warning-table tbody td {
  border: 0;
}
.full-color-table.full-warning-table tr:hover {
  background-color: #DCAA34;
  color: #ffffff;
}

.full-color-table.full-danger-table {
  background-color: #f6cdd1;
}
.full-color-table.full-danger-table thead th {
  background-color: #dc3545;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-danger-table tbody td {
  border: 0;
}
.full-color-table.full-danger-table tr:hover {
  background-color: #dc3545;
  color: #ffffff;
}

.full-color-table.full-inverse-table {
  background-color: #f2f4f7;
}
.full-color-table.full-inverse-table thead th {
  background-color: #2f3d4a;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-inverse-table tbody td {
  border: 0;
}
.full-color-table.full-inverse-table tr:hover {
  background-color: #2f3d4a;
  color: #ffffff;
}

.full-color-table.full-dark-table {
  background-color: rgba(43, 43, 43, 0.8);
}
.full-color-table.full-dark-table thead th {
  background-color: #2f2a2a;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-dark-table tbody td {
  border: 0;
  color: #ffffff;
}
.full-color-table.full-dark-table tr:hover {
  background-color: #2f2a2a;
  color: #ffffff;
}

.full-color-table.full-red-table {
  background-color: #f6cdd1;
}
.full-color-table.full-red-table thead th {
  background-color: #fb3a3a;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-red-table tbody td {
  border: 0;
}
.full-color-table.full-red-table tr:hover {
  background-color: #fb3a3a;
  color: #ffffff;
}

.full-color-table.full-purple-table {
  background-color: #fff5de;
}
.full-color-table.full-purple-table thead th {
  background-color: #7460ee;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-purple-table tbody td {
  border: 0;
}
.full-color-table.full-purple-table tr:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.full-color-table.full-muted-table {
  background-color: rgba(152, 166, 173, 0.2);
}
.full-color-table.full-muted-table thead th {
  background-color: #9e9c97;
  border: 0;
  color: #ffffff;
}
.full-color-table.full-muted-table tbody td {
  border: 0;
}
.full-color-table.full-muted-table tr:hover {
  background-color: #9e9c97;
  color: #ffffff;
}

/*******************
Table-Data Table
******************/
.dataTables_wrapper {
  padding-top: 10px;
}

.dt-buttons {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 15px;
}
.dt-buttons .dt-button {
  padding: 5px 15px;
  border-radius: 4px;
  background: #f7921e;
  color: #ffffff;
  margin-right: 3px;
}
.dt-buttons .dt-button:hover {
  background: #2f3d4a;
}

.dataTables_info, .dataTables_length {
  display: inline-block;
}

.dataTables_length {
  margin-top: 10px;
}
.dataTables_length select {
  border: 0;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  padding-bottom: 5px;
}
.dataTables_length select:focus {
  outline: none;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.dataTables_filter {
  float: right;
  margin-top: 10px;
}
.dataTables_filter input {
  border: 0;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  margin-left: 10px;
}
.dataTables_filter input:focus {
  outline: none;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  /*padding: 0.5em 1em;*/
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  *cursor: hand;
  color: #67757c;
  border: 1px solid #ddd;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #ffffff !important;
  border: 1px solid #f7921e;
  background-color: #f7921e;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #67757c;
  border: 1px solid #ddd;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white;
  border: 1px solid #f7921e;
  background-color: #f7921e;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #67757c;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

/*******************
Table- responsive
******************/
.tablesaw-bar .btn-group label {
  color: #67757c !important;
}

/*******************
Table- editable table
******************/
.dt-bootstrap {
  display: block;
}

#editable-datatable_wrapper .paging_simple_numbers .pagination .paginate_button {
  padding: 0px;
  background: #ffffff;
}
#editable-datatable_wrapper .paging_simple_numbers .pagination .paginate_button:hover {
  background: #ffffff;
}
#editable-datatable_wrapper .paging_simple_numbers .pagination .paginate_button a {
  padding: 5px 10px;
  border-radius: 4px;
  border: 0px;
}
#editable-datatable_wrapper .paging_simple_numbers .pagination .paginate_button.active a, #editable-datatable_wrapper .paging_simple_numbers .pagination .paginate_button:hover a {
  background: #1DA6F8;
  color: #ffffff;
}

/*******************
Table- Footable
******************/
#demo-show-entries {
  border: 0;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  padding-bottom: 5px;
  color: #67757c;
}
#demo-show-entries:focus {
  outline: none;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

#demo-input-search2 {
  border: 0;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  margin-left: 10px;
  color: #67757c;
}
#demo-input-search2:focus {
  outline: none;
  background-image: linear-gradient(#f7921e, #f7921e), linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.footable .pagination li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0275d8;
  background-color: #ffffff;
  border: 1px solid #ddd;
}
.footable .pagination li.active a {
  z-index: 2;
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}
.footable .pagination li.disabled a {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #ffffff;
  border-color: #ddd;
}
.footable .pagination li:first-child a {
  margin-left: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.footable-odd {
  background: #f2f4f8;
}

/*******************
Icon list fontawesom
******************/
.icon-list-demo div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  color: #67757c;
}
.icon-list-demo div:hover {
  color: #2f2a2a;
}
.icon-list-demo div p {
  margin: 10px 0;
  padding: 5px 0;
}
.icon-list-demo i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size 0.2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  transition: all 0.2s;
  transition: font-size 0.2s;
  vertical-align: middle;
  transition: all 0.3s ease 0s;
}
.icon-list-demo .col-md-4, .icon-list-demo .col-3 {
  border-radius: 4px;
}
.icon-list-demo .col-md-4:hover, .icon-list-demo .col-3:hover {
  background-color: #ebf3f5;
}

.icon-list-demo .div:hover i {
  font-size: 2em;
}

/*******************
Icon list material icon
******************/
.material-icon-list-demo .mdi {
  font-size: 21px;
}

/*******************
Gridstack Dragable portlet
******************/
.grid-stack-item-content {
  background: #fff;
  color: #2b2b2b;
  text-align: center;
  font-size: 20px;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************
Bootstrap Switch
******************/
.bootstrap-switch,
.bootstrap-switch .bootstrap-switch-container {
  border-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #ffffff;
  background: #fdb714;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #ffffff;
  background: #1DA6F8;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #ffffff;
  background: #21CB61;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #ffffff;
  background: #DCAA34;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #ffffff;
  background: #dc3545;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  color: #2f2a2a;
  background: #f2f4f8;
}

.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #21CB61;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 27px;
  background-color: #21CB61;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 24px;
  background-color: #EEEEEE;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border: 2px solid #21CB61;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*******************
Date paginator page
******************/
.dp-selected[style] {
  background-color: #f7921e !important;
}

.datepaginator-sm .pagination li a, .datepaginator-lg .pagination li a, .datepaginator .pagination li a {
  padding: 0 5px;
  height: 60px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  float: left;
  position: relative;
}

/*******************
sweet alert page
******************/
.model_img {
  cursor: pointer;
}

/*******************
Grid page
******************/
.show-grid {
  margin-bottom: 10px;
  padding: 0 15px;
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  background-color: #f2f4f8;
}

/*******************
Vertical tabs
******************/
.vtabs {
  display: table;
}
.vtabs .tabs-vertical {
  width: 150px;
  border-bottom: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  display: table-cell;
  vertical-align: top;
}
.vtabs .tabs-vertical li .nav-link {
  color: #2f2a2a;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 4px 0 0 4px;
}
.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.tabs-vertical li .nav-link.active, .tabs-vertical li .nav-link:hover, .tabs-vertical li .nav-link.active:focus {
  background: #f7921e;
  border: 0px;
  color: #ffffff;
}

/*Custom vertical tab*/
.customvtab .tabs-vertical li .nav-link.active, .customvtab .tabs-vertical li .nav-link:hover, .customvtab .tabs-vertical li .nav-link:focus {
  background: #ffffff;
  border: 0px;
  border-right: 2px solid #f7921e;
  margin-right: -1px;
  color: #f7921e;
}

.tabcontent-border {
  border: 1px solid #ddd;
  border-top: 0px;
}

.customtab2 li a.nav-link {
  border: 0px;
  margin-right: 3px;
  color: #67757c;
}
.customtab2 li a.nav-link.active {
  background: #f7921e;
  color: #ffffff;
}
.customtab2 li a.nav-link:hover {
  color: #ffffff;
  background: #f7921e;
}

/*******************
Progress bar
******************/
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  transform: rotate(180deg);
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  transition: 5s all;
}

@-webkit-keyframes myanimation {
  from {
    width: 0;
  }
}
@keyframes myanimation {
  from {
    width: 0;
  }
}
/*******************
Notification page Jquery toaster
******************/
.jq-icon-info {
  background-color: #1DA6F8;
  color: #ffffff;
}

.jq-icon-success {
  background-color: #21CB61;
  color: #ffffff;
}

.jq-icon-error {
  background-color: #dc3545;
  color: #ffffff;
}

.jq-icon-warning {
  background-color: #DCAA34;
  color: #ffffff;
}

.alert-rounded {
  border-radius: 60px;
}

/*******************
list and media
******************/
.list-group a.list-group-item:hover {
  background: #f2f4f8;
}

.list-group-item.active, .list-group .list-group-item.active:hover {
  background: #f7921e;
  border-color: #f7921e;
}

.list-group-item.disabled {
  color: #9e9c97;
  background: #f2f4f8;
}

.media {
  border: 1px solid rgba(120, 130, 140, 0.13);
  margin-bottom: 10px;
  padding: 15px;
}

/*******************
User card page
******************/
.el-element-overlay .white-box {
  padding: 0px;
}

.el-element-overlay .el-card-item {
  position: relative;
  padding-bottom: 25px;
}
.el-element-overlay .el-card-item .el-card-avatar {
  margin-bottom: 15px;
}
.el-element-overlay .el-card-item .el-card-content {
  text-align: center;
}
.el-element-overlay .el-card-item .el-card-content h3 {
  margin: 0px;
}
.el-element-overlay .el-card-item .el-card-content a {
  color: #67757c;
}
.el-element-overlay .el-card-item .el-card-content a:hover {
  color: #f7921e;
}
.el-element-overlay .el-card-item .el-overlay-1 {
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}
.el-element-overlay .el-card-item .el-overlay-1 img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  width: 100%;
  height: auto;
}
.el-element-overlay .el-card-item .el-overlay-1:hover img {
  -ms-transform: scale(1.2) translateZ(0);
  -webkit-transform: scale(1.2) translateZ(0);
  /* transform: scale(1.2) translateZ(0); */
}
.el-element-overlay .el-card-item .el-overlay-1 .el-info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #ffffff;
  background-color: transparent;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) translateZ(0);
  -webkit-transform: translateY(-50%) translateZ(0);
  -ms-transform: translateY(-50%) translateZ(0);
}
.el-element-overlay .el-card-item .el-overlay-1 .el-info > li {
  list-style: none;
  display: inline-block;
  margin: 0 3px;
}
.el-element-overlay .el-card-item .el-overlay-1 .el-info > li a {
  border-color: #ffffff;
  color: #ffffff;
  padding: 12px 15px 10px;
}
.el-element-overlay .el-card-item .el-overlay-1 .el-info > li a:hover {
  background: #f7921e;
  border-color: #f7921e;
}
.el-element-overlay .el-card-item .el-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
  top: -100%;
}
.el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
  top: 100%;
  height: 0px;
}
.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
  top: 0px;
}
.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
  top: 0px;
  height: 100%;
}

/*******************
Timeline page
******************/
.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #f2f4f8;
}

.timeline > li {
  position: relative;
  margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline > li > .timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid rgba(120, 130, 140, 0.13);
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(120, 130, 140, 0.13);
}

.timeline > li > .timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #ffffff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
}

.timeline > li > .timeline-badge {
  z-index: 10;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #fdb714;
}

.timeline-badge.success {
  background-color: #21CB61;
}

.timeline-badge.warning {
  background-color: #DCAA34;
}

.timeline-badge.danger {
  background-color: #dc3545;
}

.timeline-badge.info {
  background-color: #1DA6F8;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

/*******************
Horizontal Timeline page
******************/
.cd-horizontal-timeline .events a {
  padding-bottom: 6px;
  color: #f7921e;
}

.cd-horizontal-timeline .filling-line, .cd-horizontal-timeline .events a.selected::after {
  background: #f7921e;
}

.cd-horizontal-timeline .events a.selected::after {
  border-color: #f7921e;
}

.myadmin-dd .dd-list .dd-item .dd-handle {
  background: #ffffff;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 8px 16px;
  height: auto;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  border-radius: 0;
}

.myadmin-dd-empty .dd-list .dd3-content {
  height: auto;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 8px 16px 8px 46px;
  background: #ffffff;
  font-weight: 400;
}

.myadmin-dd-empty .dd-list .dd3-handle {
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom: 0;
  background: #ffffff;
  height: 36px;
  width: 36px;
}

.dd3-handle:before {
  color: #67757c;
  top: 7px;
}

/*******************
ribbons page
******************/
.ribbon-wrapper,
.ribbon-wrapper-reverse,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding: 50px 15px 15px 15px;
}

.ribbon-vwrapper {
  padding: 15px 15px 15px 50px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vwrapper-reverse {
  padding: 15px 50px 15px 15px;
}

.ribbon-wrapper-bottom {
  padding: 15px 15px 50px 50px;
}

.ribbon-wrapper-right-bottom {
  padding: 15px 50px 50px 15px;
}

.ribbon-content {
  margin-bottom: 0px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: -2px;
  color: #ffffff;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #2f2a2a;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2f2a2a;
  border-left: 10px solid transparent;
}

.ribbon-vertical-l,
.ribbon-vertical-r {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-r {
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-l:before,
.ribbon-bookmark.ribbon-vertical-r:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2f2a2a;
  border-bottom: 10px solid transparent;
}

.ribbon-badge {
  top: 15px;
  overflow: hidden;
  left: -90px;
  width: 100%;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-right {
  left: auto;
  right: -90px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: 15px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-right.ribbon-bottom {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-corner {
  top: 0;
  left: 0;
  background-color: transparent !important;
  padding: 6px 0 0 10px;
}

.ribbon-corner i {
  position: relative;
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 30px solid transparent;
  border-top-color: #f7921e;
  border-left-color: #f7921e;
}

.ribbon-corner.ribbon-right:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-right {
  right: 0;
  left: auto;
  padding: 6px 10px 0 0;
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069;
}

.ribbon-corner.ribbon-bottom {
  bottom: 0;
  top: auto;
  padding: 0 10px 6px 10px;
}

.ribbon-custom {
  background: #f7921e;
}

.ribbon-bookmark.ribbon-right.ribbon-custom:before {
  border-right-color: #f7921e;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-custom:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-custom:before {
  border-right-color: #f7921e;
  border-bottom-color: transparent;
}

.ribbon-primary {
  background: #fdb714;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #fdb714;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #fdb714;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-primary:before {
  border-right-color: #fdb714;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #fdb714;
  border-left-color: #fdb714;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #fdb714;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #fdb714;
}

.ribbon-success {
  background: #21CB61;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #21CB61;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #21CB61;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-success:before {
  border-right-color: #21CB61;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #21CB61;
  border-left-color: #21CB61;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #21CB61;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #21CB61;
}

.ribbon-info {
  background: #1DA6F8;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #1DA6F8;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #1DA6F8;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-info:before {
  border-right-color: #1DA6F8;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #1DA6F8;
  border-left-color: #1DA6F8;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #1DA6F8;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #1DA6F8;
}

.ribbon-warning {
  background: #DCAA34;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #DCAA34;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #DCAA34;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-warning:before {
  border-right-color: #DCAA34;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #DCAA34;
  border-left-color: #DCAA34;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #DCAA34;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #DCAA34;
}

.ribbon-danger {
  background: #dc3545;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #dc3545;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #dc3545;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-danger:before {
  border-right-color: #dc3545;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #dc3545;
  border-left-color: #dc3545;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #dc3545;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #dc3545;
}

.ribbon-default {
  background: #2f2a2a;
}

.ribbon-bookmark.ribbon-default:before {
  border-color: #2f2a2a;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-default:before {
  border-right-color: #2f2a2a;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-default:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-default:before {
  border-right-color: #2f2a2a;
  border-bottom-color: transparent;
}

.ribbon-default.ribbon-corner:before {
  border-top-color: #2f2a2a;
  border-left-color: #2f2a2a;
}

.ribbon-default.ribbon-corner.ribbon-right:before {
  border-right-color: #2f2a2a;
  border-left-color: transparent;
}

.ribbon-default.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2f2a2a;
}

/*******************
session ideal timeout page
******************/
#idletimeout {
  background: #f7921e;
  border: 3px solid #f7921e;
  color: #fff;
  font-family: arial, sans-serif;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100000;
  display: none;
}

#idletimeout a {
  color: #ffffff;
  font-weight: bold;
}

#idletimeout span {
  font-weight: bold;
}

/*******************
Stylish tooltip
******************/
.mytooltip:hover .tooltip-content2 i, .mytooltip:hover .tooltip-content2 {
  opacity: 1;
  font-size: 18px;
  pointer-events: auto;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.mytooltip:hover .tooltip-text2, .mytooltip:hover .tooltip-content4 {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.mytooltip {
  display: inline;
  position: relative;
  z-index: 10;
}
.mytooltip:hover .tooltip-item::after {
  pointer-events: auto;
}
.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}
.mytooltip:hover .tooltip-content3 {
  opacity: 1;
  pointer-events: auto;
  transform: scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-item2 {
  color: #ffffff;
  transform: translate3d(0, -0.5em, 0);
}
.mytooltip:hover .tooltip-content5 {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0s;
}
.mytooltip:hover .tooltip-text3 {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-inner2 {
  transition-delay: 0.3s;
  transform: translate3d(0, 0, 0);
}

.tooltip-item {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0 10px;
}

.tooltip-item::after {
  content: "";
  position: absolute;
  width: 360px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%);
}

.tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 360px;
  left: 50%;
  margin: 0 0 20px -180px;
  bottom: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  background: #2b2b2b;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
.tooltip-content img {
  position: relative;
  height: 140px;
  display: block;
  float: left;
  margin-right: 1em;
}

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  transform-origin: 50% 106%;
  transform: rotate3d(0, 0, 1, 15deg);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}
.tooltip-effect-5 .tooltip-text {
  padding: 1.4em;
}

.tooltip-content::after {
  content: "";
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #2a3035;
  border-width: 10px;
  margin-left: -10px;
}

.tooltip-text {
  font-size: 14px;
  line-height: 24px;
  display: block;
  padding: 1.31em 1.21em 1.21em 0;
  color: #ffffff;
}

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #f7921e;
  color: #ffffff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none;
}
.tooltip-content2 i {
  opacity: 0;
}

.tooltip-effect-6 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-6 .tooltip-content2 i {
  transform: scale3d(0, 0, 1);
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-6:hover .tooltip-content2 i {
  transform: rotate3d(1, 1, 1, 0);
}

.tooltip-content2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin: -7px 0 0 -15px;
  width: 30px;
  height: 20px;
  background: url(../../assets/images/tooltip/tooltip1.svg) no-repeat center center;
  background-size: 100%;
}

.tooltip-content3 {
  position: absolute;
  background: url(../../assets/images/tooltip/shape1.svg) no-repeat center bottom;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  cursor: default;
  font-size: 14;
  line-height: 27px;
  pointer-events: none;
  transform: scale3d(0.1, 0.2, 1);
  transform-origin: 50% 120%;
  transition: opacity 0.4s, transform 0.4s;
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
}

.tooltip-content3::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  margin-left: -8px;
  top: 100%;
  background: #00aeef;
  transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
}

.tooltip-item2 {
  color: #00aeef;
  cursor: pointer;
  z-index: 100;
  position: relative;
  display: inline-block;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #00aeef;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #ffffff;
  border-bottom: 55px solid #2b2b2b;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  transform: translate3d(0, -0.5em, 0);
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-content4 a {
  color: #2b2b2b;
}

.tooltip-text2 {
  opacity: 0;
  transform: translate3d(0, 1.5em, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #ffffff;
  background: transparent;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  transition: opacity 0.3s 0.3s;
}
.tooltip-content5 span {
  display: block;
}

.tooltip-text3 {
  border-bottom: 10px solid #f7921e;
  overflow: hidden;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s 0.3s;
}

.tooltip-inner2 {
  background: #2b2b2b;
  padding: 40px;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s;
}

.tooltip-content5::after {
  content: "";
  bottom: -20px;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #f7921e;
  border-width: 10px;
  margin-left: -10px;
}

.tooltip-effect-1 .tooltip-content {
  transform: translate3d(0, -10px, 0);
  transition: opacity 0.3s, transform 0.3s;
  color: #ffffff;
}

.tooltip-effect-2 .tooltip-content {
  transform-origin: 50% 110%;
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
  transform-origin: 50% 100%;
  transform: scale3d(0.7, 0.3, 1);
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

a.mytooltip {
  font-weight: 500;
  color: #f7921e;
}

.tooltip-effect-7 .tooltip-content2 {
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-7 .tooltip-content2 i {
  transform: translate3d(0, 15px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-8 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-8 .tooltip-content2 i {
  transform: scale3d(0, 0, 1);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-9 .tooltip-content2 {
  transform: translate3d(0, -20px, 0);
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-9 .tooltip-content2 i {
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

/*******************
Error Page
******************/
.error-box {
  height: 100%;
  position: fixed;
  background: url(../../assets/images/background/error-bg.jpg) no-repeat center center #fff;
  width: 100%;
}
.error-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}

.error-body {
  padding-top: 5%;
}
.error-body h1 {
  font-size: 210px;
  font-weight: 900;
  line-height: 210px;
}

/*******************
google map Page
******************/
.gmaps, .gmaps-panaroma {
  height: 300px;
}

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: #f2f4f8;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #fdb714;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #fdb714;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fdb714;
}

/*******************
vector map Page
******************/
.jvectormap-zoomin, .jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}

.jvectormap-zoomout {
  top: 40px;
}

/*******************
Seach listing Page
******************/
.search-listing {
  padding: 0px;
  margin: 0px;
}
.search-listing li {
  list-style: none;
  padding: 15px 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.search-listing li h3 {
  margin: 0px;
  font-size: 18px;
}
.search-listing li h3 a {
  color: #1DA6F8;
}
.search-listing li h3 a:hover {
  text-decoration: underline;
}
.search-listing li a {
  color: #21CB61;
}

/*******************
Login register and recover password Page
******************/
.login-register {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  padding: 10% 0;
  min-height: 550px;
}

.login-box {
  width: 400px;
  margin: 0 auto;
}
.login-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}
.login-box .social {
  display: block;
  margin-bottom: 30px;
}

#recoverform {
  display: none;
}

.login-sidebar {
  padding: 0px;
  margin-top: 0px;
}
.login-sidebar .login-box {
  right: 0px;
  position: absolute;
  height: 100%;
}

/*******************
FAQs Page
******************/
.minimal-faq .card {
  border: 0px;
}
.minimal-faq .card .card-header {
  background: #ffffff;
  padding: 20px 0;
  margin-top: 10px;
}
.minimal-faq .card .card-block {
  padding: 15px 0px;
}

/*******************
Pricing Page
******************/
.pricing-box {
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.featured-plan {
  margin-top: 0px;
}
.featured-plan .pricing-body {
  padding: 60px 0;
  background: #ebf3f5;
  border: 1px solid #ddd;
}
.featured-plan .price-table-content .price-row {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.pricing-body {
  border-radius: 0px;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom: 5px solid rgba(120, 130, 140, 0.13);
  vertical-align: middle;
  padding: 30px 0;
  position: relative;
}

.pricing-body h2 {
  position: relative;
  font-size: 56px;
  margin: 20px 0 10px;
  font-weight: 500;
}
.pricing-body h2 span {
  position: absolute;
  font-size: 15px;
  top: -10px;
  margin-left: -10px;
}

.price-table-content .price-row {
  padding: 20px 0;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.pricing-plan {
  padding: 0 15px;
}
.pricing-plan .no-padding {
  padding: 0px;
}

.price-lable {
  position: absolute;
  top: -10px;
  padding: 5px 10px;
  margin: 0 auto;
  display: inline-block;
  width: 100px;
  left: 0px;
  right: 0px;
}

/*******************
chat application Page
******************/
.chat-main-box {
  position: relative;
  overflow: hidden;
}
.chat-main-box .chat-left-aside {
  position: relative;
  width: 250px;
  float: left;
  z-index: 9;
  top: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}
.chat-main-box .chat-left-aside .open-panel {
  display: none;
  cursor: pointer;
  position: absolute;
  left: -webkit-calc(100% - 1px);
  top: 50%;
  z-index: 100;
  background-color: #fff;
  -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;
}
.chat-main-box .chat-left-aside .chat-left-inner {
  position: relative;
}
.chat-main-box .chat-left-aside .chat-left-inner .form-control {
  height: 60px;
  padding: 15px;
  background-image: linear-gradient(#1DA6F8, #1DA6F8), linear-gradient(rgba(120, 130, 140, 0.13), rgba(120, 130, 140, 0.13));
}
.chat-main-box .chat-left-aside .chat-left-inner .style-none {
  padding: 0px;
}
.chat-main-box .chat-left-aside .chat-left-inner .style-none li {
  list-style: none;
  overflow: hidden;
}
.chat-main-box .chat-left-aside .chat-left-inner .style-none li a {
  padding: 20px;
}
.chat-main-box .chat-left-aside .chat-left-inner .style-none li a:hover, .chat-main-box .chat-left-aside .chat-left-inner .style-none li a.active {
  background: #ebf3f5;
}
.chat-main-box .chat-right-aside {
  width: calc(100% - 250px);
  float: left;
}
.chat-main-box .chat-right-aside .chat-list {
  max-height: none;
  height: 100%;
  padding-top: 40px;
}
.chat-main-box .chat-right-aside .chat-list .chat-text {
  border-radius: 6px;
}
.chat-main-box .chat-right-aside .send-chat-box {
  position: relative;
}
.chat-main-box .chat-right-aside .send-chat-box .form-control {
  border: none;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  resize: none;
  height: 80px;
  padding-right: 180px;
}
.chat-main-box .chat-right-aside .send-chat-box .form-control:focus {
  border-color: rgba(120, 130, 140, 0.13);
}
.chat-main-box .chat-right-aside .send-chat-box .custom-send {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.chat-main-box .chat-right-aside .send-chat-box .custom-send .cst-icon {
  color: #67757c;
  margin-right: 10px;
}

/*******************
Email inbox Page
******************/
.inbox-panel .list-group .list-group-item {
  border: 0px;
  border-radius: 0px;
  border-left: 3px solid transparent;
}
.inbox-panel .list-group .list-group-item a {
  color: #67757c;
}
.inbox-panel .list-group .list-group-item.active, .inbox-panel .list-group .list-group-item:hover {
  background: #f2f4f8;
  border-left: 3px solid #f7921e;
}
.inbox-center .unread td {
  font-weight: 400;
}
.inbox-center td {
  vertical-align: middle;
  white-space: nowrap;
}
.inbox-center a {
  color: #67757c;
  padding: 2px 0 3px 0;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.inbox-center .checkbox {
  margin-top: -13px;
  height: 20px;
}

/*******************
Contact app Page
******************/
/*left-aside-column*/
.contact-page-aside {
  position: relative;
}

.left-aside {
  position: absolute;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  padding: 20px;
  width: 250px;
  height: 100%;
}

.right-aside {
  padding: 20px;
  margin-left: 250px;
}

.contact-list td {
  vertical-align: middle;
  padding: 25px 10px;
}
.contact-list td img {
  width: 30px;
}

.list-style-none {
  margin: 0px;
  padding: 0px;
}
.list-style-none li {
  list-style: none;
  margin: 0px;
}
.list-style-none li.box-label a {
  font-weight: 500;
}
.list-style-none li.divider {
  margin: 10px 0;
  height: 1px;
  background: rgba(120, 130, 140, 0.13);
}
.list-style-none li a {
  padding: 15px 10px;
  display: block;
  color: #67757c;
}
.list-style-none li a:hover {
  color: #f7921e;
}
.list-style-none li a span {
  float: right;
}

.slimScrollBar {
  z-index: 10 !important;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

/*Documentation page*/
.plugin-details {
  display: none;
}

.plugin-details-active {
  display: block;
}

/*******************
form material page
******************/
.form-control-line .form-control {
  box-shadow: none;
}

/*******************
table footable page
******************/
#demo-foo-accordion .open > .dropdown-menu {
  display: block;
}

#demo-foo-accordion2 .open > .dropdown-menu {
  display: block;
}

#footable-addrow .open > .dropdown-menu {
  display: block;
}

/*******************
/*Image Cropper Page
******************/
.docs-buttons .btn,
.docs-data .input-group {
  margin-bottom: 5px;
}

.fixed-table-container {
  overflow: hidden;
  padding-bottom: 50px !important;
}

.table-responsive > .table-bordered {
  border: 1px solid #dee2e6;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
Main sidebar
******************/
.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0px;
  z-index: 20;
  padding-top: 70px;
  background: #231f20;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.fix-sidebar .left-sidebar {
  position: fixed;
}

/*******************
use profile section
******************/
.user-profile {
  position: relative;
  background-size: cover;
}
.user-profile .profile-img {
  width: 50px;
  margin-left: 30px;
  padding: 35px 0;
  border-radius: 100%;
}
.user-profile .profile-img::before {
  -webkit-animation: 2.5s blow 0s linear infinite;
  animation: 2.5s blow 0s linear infinite;
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  top: 35px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 0;
}
@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}
@keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}
.user-profile .profile-img img {
  width: 100%;
  border-radius: 100%;
}
.user-profile .profile-text {
  padding: 5px 0px;
  position: relative;
}
.user-profile .profile-text > a {
  color: #ffffff !important;
  width: 100%;
  padding: 6px 30px;
  background: rgba(0, 0, 0, 0.5);
  display: block;
}
.user-profile .profile-text > a:after {
  position: absolute;
  right: 20px;
  top: 20px;
}
.user-profile .dropdown-menu {
  left: 0px;
  right: 0px;
  width: 180px;
  margin: 0 auto;
}

/*******************
sidebar footer
******************/
.sidebar-footer {
  position: fixed;
  z-index: 10;
  bottom: 0px;
  left: 0px;
  transition: 0.2s ease-out;
  width: 240px;
  background: #231f20;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}
.sidebar-footer a {
  padding: 15px;
  width: 33.333337%;
  float: left;
  text-align: center;
  font-size: 18px;
}

/*******************
sidebar navigation
******************/
.collapse.in {
  display: block;
}

.sidebar-nav {
  background: #231f20;
  padding: 15px;
}
.sidebar-nav > ul {
  padding: 0px 0px 60px;
}
.sidebar-nav ul {
  margin: 0px;
}
.sidebar-nav ul li {
  list-style: none;
}
.sidebar-nav ul li a {
  color: #e2ded7;
  padding: 8px 35px 8px 15px;
  display: block;
  font-size: 15px;
  border-radius: 4px;
  white-space: nowrap;
}
.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
  color: #fdb714;
}
.sidebar-nav ul li a.active {
  font-weight: 500;
  color: #2f2a2a;
}
.sidebar-nav ul li ul {
  padding-left: 5px;
  padding-top: 10px;
}
.sidebar-nav ul li ul li a {
  padding: 10px 35px 10px 15px;
}
.sidebar-nav ul li ul ul {
  padding-left: 15px;
}
.sidebar-nav ul li.nav-small-cap {
  font-size: 12px;
  margin-bottom: 0px;
  padding: 14px 14px 14px 20px;
  color: #2f2a2a;
  font-weight: 500;
}
.sidebar-nav ul li.nav-devider {
  height: 1px;
  background: rgba(120, 130, 140, 0.13);
  display: block;
  margin: 20px 0;
}
.sidebar-nav > ul > li {
  margin-bottom: 10px;
}
.sidebar-nav > ul > li.active > a {
  color: #ffffff;
  font-weight: 500;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.sidebar-nav > ul > li.active > a i {
  color: #fdb714;
}
.sidebar-nav > ul > li > a.active i, .sidebar-nav > ul > li > a:hover i {
  color: #fdb714;
}
.sidebar-nav > ul > li > a i {
  width: 27px;
  height: 27px;
  font-size: 21px;
  line-height: 27px;
  display: inline-block;
  vertical-align: middle;
  color: #e2ded7;
}
.sidebar-nav > ul > li > a .label {
  float: right;
  margin-top: 6px;
}
.sidebar-nav > ul > li > a.active {
  font-weight: 400;
  background: #242933;
  color: #fdb714;
}

.sidebar-nav .has-arrow {
  position: relative;
}
.sidebar-nav .has-arrow::after {
  position: absolute;
  content: "";
  width: 0.55em;
  height: 0.55em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #e2ded7;
  right: 1em;
  -webkit-transform: rotate(-45deg) translate(0, -50%);
  -ms-transform: rotate(-45deg) translate(0, -50%);
  -o-transform: rotate(-45deg) translate(0, -50%);
  transform: rotate(-45deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  top: 47%;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded=true]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  top: 45%;
  width: 0.58em;
  transform: rotate(-135deg) translate(0, -50%);
}

/****************
When click on sidebar toggler and also for tablet
*****************/
@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav li {
    position: relative;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    position: absolute;
    left: 60px;
    top: 45px;
    width: 200px;
    z-index: 1001;
    background: #f2f6f8;
    display: none;
    padding-left: 1px;
  }
  .mini-sidebar .sidebar-nav {
    padding: 5px;
  }
  .mini-sidebar.fix-sidebar .left-sidebar {
    position: absolute;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
    height: auto !important;
    overflow: auto;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul,
.mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse {
    display: block;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none;
  }
  .mini-sidebar .left-sidebar {
    width: 60px;
  }
  .mini-sidebar .user-profile {
    padding-bottom: 15px;
    width: 60px;
    margin-bottom: 7px;
  }
  .mini-sidebar .user-profile .profile-img {
    padding: 15px 0 0 0;
    margin: 0px 0 0 6px;
  }
  .mini-sidebar .user-profile .profile-img:before {
    top: 15px;
  }
  .mini-sidebar .scroll-sidebar {
    padding-bottom: 0px;
    position: absolute;
  }
  .mini-sidebar .hide-menu,
.mini-sidebar .nav-small-cap,
.mini-sidebar .sidebar-footer,
.mini-sidebar .user-profile .profile-text {
    display: none;
  }
  .mini-sidebar .nav-devider {
    width: 50px;
  }
  .mini-sidebar .sidebar-nav {
    background: transparent;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li > a {
    padding: 9px 15px;
    width: 50px;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 260px;
    background: #fdb714;
    color: #ffffff;
    border-color: #f7921e;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a i {
    color: #ffffff;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
    display: inline;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li.active > a {
    border-color: transparent;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li.active > a.active {
    border-color: #f7921e;
  }
}
@media (max-width: 767px) {
  .mini-sidebar .left-sidebar {
    position: fixed;
  }
  .mini-sidebar .left-sidebar, .mini-sidebar .sidebar-footer {
    left: -240px;
  }
  .mini-sidebar.show-sidebar .left-sidebar, .mini-sidebar.show-sidebar .sidebar-footer {
    left: 0px;
  }
}
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
/*User mail widgets*/
/*******************/
.topbar .top-navbar .mailbox {
  width: 300px;
}
.topbar .top-navbar .mailbox ul {
  padding: 0px;
}
.topbar .top-navbar .mailbox ul li {
  list-style: none;
}

.mailbox ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.mailbox ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px;
}
.mailbox .message-center {
  height: 200px;
  overflow: auto;
  position: relative;
}
.mailbox .message-center a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  display: block;
  text-decoration: none;
  padding: 9px 15px;
}
.mailbox .message-center a:hover {
  background: #f2f4f8;
}
.mailbox .message-center a div {
  white-space: normal;
}
.mailbox .message-center a .user-img {
  width: 40px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}
.mailbox .message-center a .user-img img {
  width: 100%;
}
.mailbox .message-center a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 30px;
  position: absolute;
  top: 1px;
  width: 10px;
}
.mailbox .message-center a .user-img .online {
  background: #21CB61;
}
.mailbox .message-center a .user-img .busy {
  background: #dc3545;
}
.mailbox .message-center a .user-img .away {
  background: #DCAA34;
}
.mailbox .message-center a .user-img .offline {
  background: #DCAA34;
}
.mailbox .message-center a .mail-contnet {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
}
.mailbox .message-center a .mail-contnet h5 {
  margin: 5px 0px 0;
}
.mailbox .message-center a .mail-contnet .mail-desc,
.mailbox .message-center a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #67757c;
  white-space: nowrap;
}

/*******************
/*Analytic info sparkline chart page*/
/*******************/
.analytics-info li span {
  font-size: 24px;
  vertical-align: middle;
}

/*******************
/*States row*/
/*******************/
.stats-row {
  margin-bottom: 20px;
}
.stats-row .stat-item {
  display: inline-block;
  padding-right: 15px;
}
.stats-row .stat-item + .stat-item {
  padding-left: 15px;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

/*******************/
/*Dashboard1 Weather*/
/*******************/
.city-weather-days {
  margin: 0px;
}
.city-weather-days li {
  text-align: center;
  padding: 15px 0;
}
.city-weather-days li span {
  display: block;
  padding: 10px 0 0;
  color: #9e9c97;
}
.city-weather-days li i {
  display: block;
  font-size: 20px;
  color: #f7921e;
}
.city-weather-days li h3 {
  font-weight: 300;
  margin-top: 5px;
}

/*******************/
/*Comment widgets*/
/*******************/
.comment-widgets {
  position: relative;
  margin-bottom: 10px;
}
.comment-widgets .comment-row {
  border-left: 3px solid #ffffff;
  padding: 15px;
}
.comment-widgets .comment-row:hover, .comment-widgets .comment-row.active {
  border-color: #f7921e;
}

.comment-text {
  padding: 15px 15px 15px 20px;
}
.comment-text:hover .comment-footer .action-icons, .comment-text.active .comment-footer .action-icons {
  visibility: visible;
}
.comment-text p {
  max-height: 50px;
  overflow: hidden;
}

.comment-footer .action-icons {
  visibility: hidden;
}
.comment-footer .action-icons a {
  padding-left: 15px;
  vertical-align: middle;
  color: #9e9c97;
}
.comment-footer .action-icons a:hover, .comment-footer .action-icons a.active {
  color: #1DA6F8;
}

/*******************/
/*To do widgets*/
/*******************/
.todo-list li {
  border: 0px;
  margin-bottom: 0px;
  padding: 20px 15px 15px 0px;
}
.todo-list li .checkbox {
  width: 100%;
}
.todo-list li .checkbox label {
  font-weight: 400;
  color: #2f2a2a;
}
.todo-list li:last-child {
  border-bottom: 0px;
}
.todo-list li .assignedto {
  padding: 0px 0 0 27px;
  margin: 0px;
}
.todo-list li .assignedto li {
  list-style: none;
  padding: 0px;
  display: inline-block;
  border: 0px;
  margin-right: 2px;
}
.todo-list li .assignedto li img {
  width: 30px;
  border-radius: 100%;
}
.todo-list li .item-date {
  padding-left: 25px;
  font-size: 12px;
  display: inline-block;
}

.list-task .task-done span {
  text-decoration: line-through;
}

/*******************/
/*Chat widget*/
/*******************/
.chat-list {
  margin: 0px;
  padding: 0px;
}
.chat-list li {
  list-style: none;
  margin-top: 30px;
}
.chat-list li .chat-img {
  display: inline-block;
  width: 45px;
  vertical-align: top;
}
.chat-list li .chat-img img {
  width: 45px;
  border-radius: 100%;
}
.chat-list li .chat-content {
  width: calc(100% - 140px);
  display: inline-block;
  padding-left: 15px;
}
.chat-list li .chat-content h5 {
  color: #9e9c97;
}
.chat-list li .chat-content .box {
  display: inline-block;
  margin-bottom: 10px;
  color: #2f2a2a;
}
.chat-list li .chat-time {
  display: inline-block;
  text-align: right;
  width: 80px;
  font-size: 13px;
  color: #9e9c97;
}
.chat-list li.odd .chat-content {
  text-align: right;
  width: calc(100% - 90px);
}
.chat-list li.odd .box {
  clear: both;
}
.chat-list li.odd + .odd {
  margin-top: 0px;
}
.chat-list li.reverse {
  text-align: right;
}
.chat-list li.reverse .chat-time {
  text-align: left;
}
.chat-list li.reverse .chat-content {
  padding-left: 0px;
  padding-right: 15px;
}

/*******************/
/*Chat widget*/
/*******************/
.message-box ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.message-box ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  padding-top: 15px;
}
.message-box .message-widget {
  position: relative;
}
.message-box .message-widget a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  display: block;
  text-decoration: none;
  padding: 9px 15px;
}
.message-box .message-widget a:hover {
  background: #f2f4f8;
}
.message-box .message-widget a:last-child {
  border-bottom: 0px;
}
.message-box .message-widget a div {
  white-space: normal;
}
.message-box .message-widget a .user-img {
  width: 45px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}
.message-box .message-widget a .user-img img {
  width: 100%;
}
.message-box .message-widget a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 10px;
}
.message-box .message-widget a .user-img .online {
  background: #21CB61;
}
.message-box .message-widget a .user-img .busy {
  background: #dc3545;
}
.message-box .message-widget a .user-img .away {
  background: #DCAA34;
}
.message-box .message-widget a .user-img .offline {
  background: #DCAA34;
}
.message-box .message-widget a .mail-contnet {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
}
.message-box .message-widget a .mail-contnet h5 {
  margin: 5px 0px 0;
}
.message-box .message-widget a .mail-contnet .mail-desc,
.message-box .message-widget a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #67757c;
  white-space: nowrap;
}

/*******************/
/*Calendar widget*/
/*******************/
.calendar {
  float: left;
  margin-bottom: 0px;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day {
  background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc-widget-header {
  border: 0px !important;
}

.fc-widget-content {
  border-color: rgba(120, 130, 140, 0.13) !important;
}

.fc th.fc-widget-header {
  color: #67757c;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  padding: 7px 0px;
  text-transform: uppercase;
}
.fc th.fc-sun,
.fc th.fc-tue,
.fc th.fc-thu,
.fc th.fc-sat {
  background: #f2f7f8;
}
.fc th.fc-mon,
.fc th.fc-wed,
.fc th.fc-fri {
  background: #f2f7f8;
}

.fc-view {
  margin-top: 0px;
}

.fc-toolbar {
  margin: 0px;
  padding: 24px 0px;
}

.fc-button {
  background: #ffffff;
  border: 1px solid rgba(120, 130, 140, 0.13);
  color: #67757c;
  text-transform: capitalize;
}
.fc-button:hover {
  background: #f2f4f8;
  opacity: 0.8;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #F5F5F5;
}

.fc-unthemed .fc-today {
  border: 1px solid #dc3545;
  background: #f2f4f8 !important;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-event {
  border-radius: 0px;
  border: none;
  cursor: move;
  color: #ffffff !important;
  font-size: 13px;
  margin: 1px -1px 0 -1px;
  padding: 5px 5px;
  text-align: center;
  background: #1DA6F8;
}

.calendar-event {
  cursor: move;
  margin: 10px 5px 0 0;
  padding: 6px 10px;
  display: inline-block;
  color: #ffffff;
  min-width: 140px;
  text-align: center;
  background: #1DA6F8;
}
.calendar-event a {
  float: right;
  opacity: 0.6;
  font-size: 10px;
  margin: 4px 0 0 10px;
  color: #ffffff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}
.fc-basic-view .fc-day-number {
  padding: 10px 15px;
  display: inline-block;
}

/*******************/
/*Steam line widget*/
/*******************/
.steamline {
  position: relative;
  border-left: 1px solid rgba(120, 130, 140, 0.13);
  margin-left: 20px;
}
.steamline .sl-left {
  float: left;
  margin-left: -20px;
  z-index: 1;
  width: 40px;
  line-height: 40px;
  text-align: center;
  height: 40px;
  border-radius: 100%;
  color: #ffffff;
  background: #2f2a2a;
  margin-right: 15px;
}
.steamline .sl-left img {
  max-width: 40px;
}

.steamline .sl-right {
  padding-left: 50px;
}
.steamline .sl-right .desc,
.steamline .sl-right .inline-photos {
  margin-bottom: 30px;
}

.steamline .sl-item {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin: 20px 0;
}

.sl-date {
  font-size: 10px;
  color: #9e9c97;
}

.time-item {
  border-color: rgba(120, 130, 140, 0.13);
  padding-bottom: 1px;
  position: relative;
}
.time-item:before {
  content: " ";
  display: table;
}
.time-item:after {
  background-color: #ffffff;
  border-color: rgba(120, 130, 140, 0.13);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: "";
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.time-item-item:after {
  content: " ";
  display: table;
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}
.item-info p {
  margin-bottom: 10px !important;
}

/*******************/
/*Feed widget*/
/*******************/
.feeds {
  margin: 0px;
  padding: 0px;
}
.feeds li {
  list-style: none;
  padding: 10px;
  display: block;
}
.feeds li:hover {
  background: #ebf3f5;
}
.feeds li > div {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
}
.feeds li > div i {
  line-height: 40px;
}
.feeds li span {
  float: right;
  width: auto;
  font-size: 12px;
}

/*******************/
/*Vertical carousel*/
/*******************/
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

/*******************/
/*social-widgets*/
/*******************/
.social-widget .soc-header {
  padding: 15px;
  text-align: center;
  font-size: 36px;
  color: #fff;
}
.social-widget .soc-header.box-facebook {
  background: #3b5998;
}
.social-widget .soc-header.box-twitter {
  background: #00aced;
}
.social-widget .soc-header.box-google {
  background: #f86c6b;
}
.social-widget .soc-header.box-linkedin {
  background: #4875b4;
}
.social-widget .soc-content {
  display: flex;
  text-align: center;
}
.social-widget .soc-content div {
  padding: 10px;
}
.social-widget .soc-content div h3 {
  margin-bottom: 0px;
}

/*******************/
/*Guage chart*/
/*******************/
.gaugejs-box {
  position: relative;
  margin: 0 auto;
}

.gaugejs-box canvas.gaugejs {
  width: 100% !important;
  height: auto !important;
}

/*******************/
/*social-profile-first*/
/*******************/
.social-profile-first {
  text-align: center;
  padding-top: 22%;
  margin-bottom: 96px;
}
.social-profile-first.bg-over {
  background: rgba(56, 83, 161, 0.7);
}
.social-profile-first .middle {
  vertical-align: middle;
}

/*******************/
/*country-state*/
/*******************/
.country-state {
  list-style: none;
  margin: 0;
  padding: 0 0 0 10px;
}
.country-state li {
  margin-top: 30px;
  margin-bottom: 10px;
}
.country-state h2 {
  margin-bottom: 0px;
  font-weight: 400;
}

/*******************/
/*profile timeline widget*/
/*******************/
.profiletimeline {
  position: relative;
  margin-left: 70px;
  margin-right: 10px;
}
.profiletimeline .sl-left {
  float: left;
  margin-left: -60px;
  z-index: 1;
  margin-right: 15px;
}
.profiletimeline .sl-left img {
  max-width: 40px;
}

.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px;
}

.profiletimeline .sl-date {
  font-size: 12px;
  color: #9e9c97;
}

.profiletimeline .time-item {
  border-color: rgba(120, 130, 140, 0.13);
  padding-bottom: 1px;
  position: relative;
}
.profiletimeline .time-item:before {
  content: " ";
  display: table;
}
.profiletimeline .time-item:after {
  background-color: #ffffff;
  border-color: rgba(120, 130, 140, 0.13);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: "";
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.profiletimeline .time-item-item:after {
  content: " ";
  display: table;
}

.profiletimeline .item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}
.profiletimeline .item-info p {
  margin-bottom: 10px !important;
}

/*Blog widgets*/
.blog-widget {
  margin-top: 30px;
}
.blog-widget .blog-image img {
  border-radius: 4px;
  margin-top: -45px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/*Weather smal;*/
.weather-small h1 {
  line-height: 30px;
}
.weather-small sup {
  font-size: 60%;
}

/*little-profile*/
.little-profile .pro-img {
  margin-top: -80px;
  margin-bottom: 20px;
}
.little-profile .pro-img img {
  width: 128px;
  height: 128px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

.contact-box {
  position: relative;
}
.contact-box .add-ct-btn {
  position: absolute;
  right: 4px;
  top: -46px;
}
.contact-box .contact-widget > a {
  padding: 15px 10px;
}
.contact-box .contact-widget > a .user-img {
  margin-bottom: 0px !important;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@media (min-width: 1600px) {
  .col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
    float: left;
  }
  .col-xlg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66666667%;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xlg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333333%;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xlg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66666667%;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xlg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333333%;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xlg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66666667%;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xlg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333333%;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xlg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66666667%;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xlg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333333%;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.66666667%;
  }
  .col-xlg-pull-10 {
    right: 83.33333333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.66666667%;
  }
  .col-xlg-pull-7 {
    right: 58.33333333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.66666667%;
  }
  .col-xlg-pull-4 {
    right: 33.33333333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.66666667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333333%;
  }
  .col-xlg-pull-0 {
    right: auto;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.66666667%;
  }
  .col-xlg-push-10 {
    left: 83.33333333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.66666667%;
  }
  .col-xlg-push-7 {
    left: 58.33333333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.66666667%;
  }
  .col-xlg-push-4 {
    left: 33.33333333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.66666667%;
  }
  .col-xlg-push-1 {
    left: 8.33333333%;
  }
  .col-xlg-push-0 {
    left: auto;
  }
  .offset-xlg-12 {
    margin-left: 100%;
  }
  .offset-xlg-11 {
    margin-left: 91.66666667%;
  }
  .offset-xlg-10 {
    margin-left: 83.33333333%;
  }
  .offset-xlg-9 {
    margin-left: 75%;
  }
  .offset-xlg-8 {
    margin-left: 66.66666667%;
  }
  .offset-xlg-7 {
    margin-left: 58.33333333%;
  }
  .offset-xlg-6 {
    margin-left: 50%;
  }
  .offset-xlg-5 {
    margin-left: 41.66666667%;
  }
  .offset-xlg-4 {
    margin-left: 33.33333333%;
  }
  .offset-xlg-3 {
    margin-left: 25%;
  }
  .offset-xlg-2 {
    margin-left: 16.66666667%;
  }
  .offset-xlg-1 {
    margin-left: 8.33333333%;
  }
  .offset-xlg-0 {
    margin-left: 0;
  }
}
.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/*-------------------*/
/*Bootstrap 4 hack*/
/*-------------------*/
.bootstrap-touchspin .input-group-btn {
  align-items: normal;
}

.form-control-line .form-control.form-control-success:focus {
  border-bottom: 1px solid #21CB61;
}
.form-control-line .form-control.form-control-warning:focus {
  border-bottom: 1px solid #DCAA34;
}
.form-control-line .form-control.form-control-danger:focus {
  border-bottom: 1px solid #dc3545;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #21CB61;
}

.has-success .form-control-success {
  background-image: url(../../assets/images/icon/success.svg);
}

.has-success .form-control {
  border-color: #21CB61;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #DCAA34;
}

.has-warning .form-control-warning {
  background-image: url(../../assets/images/icon/warning.svg);
}

.has-warning .form-control {
  border-color: #DCAA34;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #dc3545;
}

.has-danger .form-control-danger {
  background-image: url(../../assets/images/icon/danger.svg);
}

.has-danger .form-control {
  border-color: #dc3545;
}

.input-group-addon [type=radio]:not(:checked),
.input-group-addon [type=radio]:checked,
.input-group-addon [type=checkbox]:not(:checked),
.input-group-addon [type=checkbox]:checked {
  position: initial;
  opacity: 1;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}
.hidden-xl-down {
  display: none !important;
}

.card-inverse .card-blockquote,
.card-inverse .card-footer,
.card-inverse .card-header,
.card-inverse .card-title {
  color: #ffffff;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */
@media (min-width: 1650px) {
  .widget-app-columns {
    column-count: 3;
  }
  .campaign {
    height: 365px !important;
  }
}
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */
@media (max-width: 1370px) {
  .widget-app-columns {
    column-count: 2;
  }
}
/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (min-width: 1024px) {
  .page-wrapper {
    margin-left: 240px;
  }
  .footer {
    left: 240px;
  }
}
@media (max-width: 1023px) {
  .page-wrapper {
    margin-left: 60px;
    transition: 0.2s ease-in;
  }
  .footer {
    left: 60px;
  }
  .widget-app-columns {
    column-count: 1;
  }
  .inbox-center a {
    width: 200px;
  }
}
/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
@media (min-width: 768px) {
  .navbar-header {
    width: 240px;
    flex-shrink: 0;
  }
  .navbar-header .navbar-brand {
    padding-top: 0px;
  }
  .material-icon-list-demo .icons div {
    width: 33%;
    padding: 15px;
    display: inline-block;
    line-height: 40px;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }
  .mini-sidebar .footer {
    left: 60px;
  }
  .flex-wrap {
    flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap !important;
  }
}
/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@media (max-width: 767px) {
  /*Header*/
  .topbar {
    position: fixed;
    width: 100%;
  }
  .topbar .top-navbar {
    padding-right: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-align-items: center;
  }
  .topbar .top-navbar .navbar-collapse {
    display: flex;
    width: 100%;
  }
  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }
  .topbar .top-navbar .navbar-nav > .nav-item.show {
    position: static;
  }
  .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
    width: 100%;
    margin-top: 0px;
  }
  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .topbar .top-navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .mega-dropdown .dropdown-menu {
    height: 480px;
    overflow: auto;
  }
  /*Sidebar and wrapper*/
  .mini-sidebar .page-wrapper {
    margin-left: 0px;
    padding-top: 70px;
  }
  .comment-text .comment-footer .action-icons {
    display: block;
    padding: 10px 0;
  }
  .vtabs .tabs-vertical {
    width: auto;
  }
  /*Footer*/
  .footer {
    left: 0px;
  }
  .material-icon-list-demo .icons div {
    width: 100%;
  }
  .error-page .footer {
    position: fixed;
    bottom: 0px;
    z-index: 10;
  }
  .error-box {
    position: relative;
    padding-bottom: 60px;
  }
  .error-body {
    padding-top: 10%;
  }
  .error-body h1 {
    font-size: 100px;
    font-weight: 600;
    line-height: 100px;
  }
  .login-register {
    position: relative;
    overflow: hidden;
  }
  .login-box {
    width: 90%;
  }
  .login-sidebar {
    padding: 10% 0;
  }
  .login-sidebar .login-box {
    position: relative;
  }
  /*This is for chat page*/
  .chat-main-box .chat-left-aside {
    left: -250px;
    position: absolute;
    transition: 0.5s ease-in;
    background: #ffffff;
  }
  .chat-main-box .chat-left-aside.open-pnl {
    left: 0px;
  }
  .chat-main-box .chat-left-aside .open-panel {
    display: block;
  }
  .chat-main-box .chat-right-aside {
    width: 100%;
  }
  /*Timeline*/
  ul.timeline:before {
    left: 40px;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
  }
  ul.timeline > li > .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
  }
  ul.timeline > li > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  ul.timeline > li > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  /*Contact app page*/
  .left-aside {
    width: 100%;
    position: relative;
    border: 0px;
  }
  .right-aside {
    margin-left: 0px;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
  }
  .chat-list li .chat-content {
    width: calc(100% - 80px);
  }
}
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}
.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/* Radio Buttons
   ========================================================================== */
[type=radio]:not(:checked),
[type=radio]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type=radio]:not(:checked) + label,
[type=radio]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: 0.28s ease;
  /* webkit (konqueror) browsers */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type=radio] + label:before,
[type=radio] + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  z-index: 0;
  transition: 0.28s ease;
}

/* Unchecked styles */
[type=radio]:not(:checked) + label:before,
[type=radio]:not(:checked) + label:after,
[type=radio]:checked + label:before,
[type=radio]:checked + label:after,
[type=radio].with-gap:checked + label:before,
[type=radio].with-gap:checked + label:after {
  border-radius: 50%;
}

[type=radio]:not(:checked) + label:before,
[type=radio]:not(:checked) + label:after {
  border: 2px solid #5a5a5a;
}

[type=radio]:not(:checked) + label:after {
  z-index: -1;
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Checked styles */
[type=radio]:checked + label:before {
  border: 2px solid transparent;
  animation: ripple 0.2s linear forwards;
}

[type=radio]:checked + label:after,
[type=radio].with-gap:checked + label:before,
[type=radio].with-gap:checked + label:after {
  border: 2px solid #26a69a;
}

[type=radio]:checked + label:after,
[type=radio].with-gap:checked + label:after {
  background-color: #26a69a;
  z-index: 0;
}

[type=radio]:checked + label:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

/* Radio With gap */
[type=radio].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

/* Focused styles */
[type=radio].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ripple 0.2s linear forwards;
}

/* Disabled Radio With gap */
[type=radio].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26);
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

/* Disabled style */
[type=radio]:disabled:not(:checked) + label:before,
[type=radio]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  animation: ripple 0.2s linear forwards;
}

[type=radio]:disabled + label {
  color: rgba(0, 0, 0, 0.26);
}

[type=radio]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}

[type=radio]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD;
}

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left;
}

form p:last-child {
  margin-bottom: 0;
}

/* Remove default checkbox */
[type=checkbox]:not(:checked),
[type=checkbox]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type=checkbox] {
  /* checkbox aspect */
}

[type=checkbox] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

[type=checkbox] + label:before,
[type=checkbox]:not(.filled-in) + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  transition: 0.2s;
}

[type=checkbox]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=checkbox]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type=checkbox].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type=checkbox]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

/* Indeterminate checkbox */
[type=checkbox]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

[type=checkbox].filled-in + label:after {
  border-radius: 2px;
}

[type=checkbox].filled-in + label:before,
[type=checkbox].filled-in + label:after {
  content: "";
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

[type=checkbox].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type=checkbox].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type=checkbox].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type=checkbox].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type=checkbox].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type=checkbox].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type=checkbox].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD;
}

[type=checkbox].filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type=checkbox].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD;
}

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type=checkbox]:checked + .lever {
  background-color: #84c7c1;
}

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #26a69a;
  left: 24px;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: #F1F1F1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
}

.switch input[type=checkbox][disabled] + .lever {
  cursor: default;
}

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #BDBDBD;
}

.scale-up {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  transform-origin: right 0px;
}

.scale-up-left {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: inline-block;
  transform-origin: left 0px;
}

.show > .scale-up {
  transform: scale(1);
  transform-origin: right 0px;
}

.show > .scale-up-left {
  transform: scale(1);
  transform-origin: left 0px;
}

/*panels*/
.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.well, pre {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.page-titles .justify-content-end:last-child .d-flex {
  margin-right: 10px;
}

.btn-circle.right-side-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 25px;
}

/*Radio button*/
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px transparent;
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 15px transparent;
  }
}
/*Bootstrap Select*/
.bootstrap-select.btn-group .dropdown-menu {
  margin-top: -40px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.demo-checkbox label, .demo-radio-button label {
  min-width: 200px;
  margin-bottom: 20px;
}

.demo-swtich .demo-switch-title, .demo-swtich .switch {
  width: 150px;
  margin-bottom: 10px;
  display: inline-block;
}

[type=checkbox] + label {
  padding-left: 26px;
  height: 25px;
  line-height: 21px;
  font-weight: normal;
}
[type=checkbox]:checked + label:before {
  top: -4px;
  left: -2px;
  width: 11px;
  height: 19px;
}
[type=checkbox]:checked.chk-col-red + label:before {
  border-right: 2px solid #fb3a3a;
  border-bottom: 2px solid #fb3a3a;
}
[type=checkbox]:checked.chk-col-pink + label:before {
  border-right: 2px solid #E91E63;
  border-bottom: 2px solid #E91E63;
}
[type=checkbox]:checked.chk-col-purple + label:before {
  border-right: 2px solid #7460ee;
  border-bottom: 2px solid #7460ee;
}
[type=checkbox]:checked.chk-col-deep-purple + label:before {
  border-right: 2px solid #673AB7;
  border-bottom: 2px solid #673AB7;
}
[type=checkbox]:checked.chk-col-indigo + label:before {
  border-right: 2px solid #3F51B5;
  border-bottom: 2px solid #3F51B5;
}
[type=checkbox]:checked.chk-col-blue + label:before {
  border-right: 2px solid #02bec9;
  border-bottom: 2px solid #02bec9;
}
[type=checkbox]:checked.chk-col-light-blue + label:before {
  border-right: 2px solid #03A9F4;
  border-bottom: 2px solid #03A9F4;
}
[type=checkbox]:checked.chk-col-cyan + label:before {
  border-right: 2px solid #00BCD4;
  border-bottom: 2px solid #00BCD4;
}
[type=checkbox]:checked.chk-col-teal + label:before {
  border-right: 2px solid #009688;
  border-bottom: 2px solid #009688;
}
[type=checkbox]:checked.chk-col-green + label:before {
  border-right: 2px solid #26c6da;
  border-bottom: 2px solid #26c6da;
}
[type=checkbox]:checked.chk-col-light-green + label:before {
  border-right: 2px solid #8BC34A;
  border-bottom: 2px solid #8BC34A;
}
[type=checkbox]:checked.chk-col-lime + label:before {
  border-right: 2px solid #CDDC39;
  border-bottom: 2px solid #CDDC39;
}
[type=checkbox]:checked.chk-col-yellow + label:before {
  border-right: 2px solid #ffe821;
  border-bottom: 2px solid #ffe821;
}
[type=checkbox]:checked.chk-col-amber + label:before {
  border-right: 2px solid #FFC107;
  border-bottom: 2px solid #FFC107;
}
[type=checkbox]:checked.chk-col-orange + label:before {
  border-right: 2px solid #FF9800;
  border-bottom: 2px solid #FF9800;
}
[type=checkbox]:checked.chk-col-deep-orange + label:before {
  border-right: 2px solid #FF5722;
  border-bottom: 2px solid #FF5722;
}
[type=checkbox]:checked.chk-col-brown + label:before {
  border-right: 2px solid #795548;
  border-bottom: 2px solid #795548;
}
[type=checkbox]:checked.chk-col-grey + label:before {
  border-right: 2px solid #9E9E9E;
  border-bottom: 2px solid #9E9E9E;
}
[type=checkbox]:checked.chk-col-blue-grey + label:before {
  border-right: 2px solid #607D8B;
  border-bottom: 2px solid #607D8B;
}
[type=checkbox]:checked.chk-col-black + label:before {
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000;
}
[type=checkbox]:checked.chk-col-white + label:before {
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

[type=checkbox].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}
[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}
[type=checkbox].filled-in:checked.chk-col-red + label:after {
  border: 2px solid #fb3a3a;
  background-color: #fb3a3a;
}
[type=checkbox].filled-in:checked.chk-col-pink + label:after {
  border: 2px solid #E91E63;
  background-color: #E91E63;
}
[type=checkbox].filled-in:checked.chk-col-purple + label:after {
  border: 2px solid #7460ee;
  background-color: #7460ee;
}
[type=checkbox].filled-in:checked.chk-col-deep-purple + label:after {
  border: 2px solid #673AB7;
  background-color: #673AB7;
}
[type=checkbox].filled-in:checked.chk-col-indigo + label:after {
  border: 2px solid #3F51B5;
  background-color: #3F51B5;
}
[type=checkbox].filled-in:checked.chk-col-blue + label:after {
  border: 2px solid #02bec9;
  background-color: #02bec9;
}
[type=checkbox].filled-in:checked.chk-col-light-blue + label:after {
  border: 2px solid #03A9F4;
  background-color: #03A9F4;
}
[type=checkbox].filled-in:checked.chk-col-cyan + label:after {
  border: 2px solid #00BCD4;
  background-color: #00BCD4;
}
[type=checkbox].filled-in:checked.chk-col-teal + label:after {
  border: 2px solid #009688;
  background-color: #009688;
}
[type=checkbox].filled-in:checked.chk-col-green + label:after {
  border: 2px solid #26c6da;
  background-color: #26c6da;
}
[type=checkbox].filled-in:checked.chk-col-light-green + label:after {
  border: 2px solid #8BC34A;
  background-color: #8BC34A;
}
[type=checkbox].filled-in:checked.chk-col-lime + label:after {
  border: 2px solid #CDDC39;
  background-color: #CDDC39;
}
[type=checkbox].filled-in:checked.chk-col-yellow + label:after {
  border: 2px solid #ffe821;
  background-color: #ffe821;
}
[type=checkbox].filled-in:checked.chk-col-amber + label:after {
  border: 2px solid #FFC107;
  background-color: #FFC107;
}
[type=checkbox].filled-in:checked.chk-col-orange + label:after {
  border: 2px solid #FF9800;
  background-color: #FF9800;
}
[type=checkbox].filled-in:checked.chk-col-deep-orange + label:after {
  border: 2px solid #FF5722;
  background-color: #FF5722;
}
[type=checkbox].filled-in:checked.chk-col-brown + label:after {
  border: 2px solid #795548;
  background-color: #795548;
}
[type=checkbox].filled-in:checked.chk-col-grey + label:after {
  border: 2px solid #9E9E9E;
  background-color: #9E9E9E;
}
[type=checkbox].filled-in:checked.chk-col-blue-grey + label:after {
  border: 2px solid #607D8B;
  background-color: #607D8B;
}
[type=checkbox].filled-in:checked.chk-col-black + label:after {
  border: 2px solid #000000;
  background-color: #000000;
}
[type=checkbox].filled-in:checked.chk-col-white + label:after {
  border: 2px solid #ffffff;
  background-color: #ffffff;
}

[type=radio]:not(:checked) + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal;
}
[type=radio]:checked + label {
  padding-left: 26px;
  height: 25px;
  line-height: 25px;
  font-weight: normal;
}

[type=radio].radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border-color: #fb3a3a;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border-color: #E91E63;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border-color: #7460ee;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border-color: #673AB7;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border-color: #3F51B5;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border-color: #02bec9;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border-color: #03A9F4;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border-color: #00BCD4;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-teal:checked + label:after {
  background-color: #009688;
  border-color: #009688;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-green:checked + label:after {
  background-color: #26c6da;
  border-color: #26c6da;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border-color: #8BC34A;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border-color: #CDDC39;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border-color: #ffe821;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border-color: #FFC107;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border-color: #FF9800;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border-color: #FF5722;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-brown:checked + label:after {
  background-color: #795548;
  border-color: #795548;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border-color: #9E9E9E;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border-color: #607D8B;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-black:checked + label:after {
  background-color: #000000;
  border-color: #000000;
  animation: ripple 0.2s linear forwards;
}

[type=radio].radio-col-white:checked + label:after {
  background-color: #ffffff;
  border-color: #ffffff;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-red:checked + label:before {
  border: 2px solid #fb3a3a;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-pink:checked + label:before {
  border: 2px solid #E91E63;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-pink:checked + label:after {
  background-color: #E91E63;
  border: 2px solid #E91E63;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-purple:checked + label:before {
  border: 2px solid #7460ee;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-purple:checked + label:before {
  border: 2px solid #673AB7;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-deep-purple:checked + label:after {
  background-color: #673AB7;
  border: 2px solid #673AB7;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-indigo:checked + label:before {
  border: 2px solid #3F51B5;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-indigo:checked + label:after {
  background-color: #3F51B5;
  border: 2px solid #3F51B5;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-blue:checked + label:before {
  border: 2px solid #02bec9;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-light-blue:checked + label:before {
  border: 2px solid #03A9F4;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-light-blue:checked + label:after {
  background-color: #03A9F4;
  border: 2px solid #03A9F4;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-cyan:checked + label:before {
  border: 2px solid #00BCD4;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-cyan:checked + label:after {
  background-color: #00BCD4;
  border: 2px solid #00BCD4;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-teal:checked + label:before {
  border: 2px solid #009688;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-teal:checked + label:after {
  background-color: #009688;
  border: 2px solid #009688;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-green:checked + label:before {
  border: 2px solid #26c6da;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-green:checked + label:after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-light-green:checked + label:before {
  border: 2px solid #8BC34A;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-light-green:checked + label:after {
  background-color: #8BC34A;
  border: 2px solid #8BC34A;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-lime:checked + label:before {
  border: 2px solid #CDDC39;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-lime:checked + label:after {
  background-color: #CDDC39;
  border: 2px solid #CDDC39;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-yellow:checked + label:before {
  border: 2px solid #ffe821;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-amber:checked + label:before {
  border: 2px solid #FFC107;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-amber:checked + label:after {
  background-color: #FFC107;
  border: 2px solid #FFC107;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-orange:checked + label:before {
  border: 2px solid #FF9800;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-orange:checked + label:after {
  background-color: #FF9800;
  border: 2px solid #FF9800;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-orange:checked + label:before {
  border: 2px solid #FF5722;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-deep-orange:checked + label:after {
  background-color: #FF5722;
  border: 2px solid #FF5722;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-brown:checked + label:before {
  border: 2px solid #795548;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-brown:checked + label:after {
  background-color: #795548;
  border: 2px solid #795548;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-grey:checked + label:before {
  border: 2px solid #9E9E9E;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-grey:checked + label:after {
  background-color: #9E9E9E;
  border: 2px solid #9E9E9E;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-blue-grey:checked + label:before {
  border: 2px solid #607D8B;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-blue-grey:checked + label:after {
  background-color: #607D8B;
  border: 2px solid #607D8B;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-black:checked + label:before {
  border: 2px solid #000000;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-black:checked + label:after {
  background-color: #000000;
  border: 2px solid #000000;
  animation: ripple 0.2s linear forwards;
}

[type=radio].with-gap.radio-col-white:checked + label:before {
  border: 2px solid #ffffff;
  animation: ripple 0.2s linear forwards;
}
[type=radio].with-gap.radio-col-white:checked + label:after {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  animation: ripple 0.2s linear forwards;
}

.switch label {
  font-weight: normal;
  font-size: 13px;
}
.switch label .lever {
  margin: 0 14px;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-red:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-red {
  background-color: rgba(251, 58, 58, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-red:after {
  background-color: #fb3a3a;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-pink:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-pink {
  background-color: rgba(233, 30, 99, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-pink:after {
  background-color: #E91E63;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-purple:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-purple {
  background-color: rgba(116, 96, 238, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-purple:after {
  background-color: #7460ee;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-purple:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple {
  background-color: rgba(103, 58, 183, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-deep-purple:after {
  background-color: #673AB7;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-indigo:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-indigo {
  background-color: rgba(63, 81, 181, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-indigo:after {
  background-color: #3F51B5;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-blue {
  background-color: rgba(2, 190, 201, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-blue:after {
  background-color: #02bec9;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-blue:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-light-blue {
  background-color: rgba(3, 169, 244, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-light-blue:after {
  background-color: #03A9F4;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-cyan:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-cyan {
  background-color: rgba(0, 188, 212, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-cyan:after {
  background-color: #00BCD4;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-teal:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-teal {
  background-color: rgba(0, 150, 136, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-teal:after {
  background-color: #009688;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-green:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-green {
  background-color: rgba(38, 198, 218, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-green:after {
  background-color: #26c6da;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-light-green:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-light-green {
  background-color: rgba(139, 195, 74, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-light-green:after {
  background-color: #8BC34A;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-lime:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-lime {
  background-color: rgba(205, 220, 57, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-lime:after {
  background-color: #CDDC39;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-yellow:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-yellow {
  background-color: rgba(255, 232, 33, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-yellow:after {
  background-color: #ffe821;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-amber:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-amber {
  background-color: rgba(255, 193, 7, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-amber:after {
  background-color: #FFC107;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-orange:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-orange {
  background-color: rgba(255, 152, 0, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-orange:after {
  background-color: #FF9800;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-deep-orange:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange {
  background-color: rgba(255, 87, 34, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-deep-orange:after {
  background-color: #FF5722;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-brown:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-brown {
  background-color: rgba(121, 85, 72, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-brown:after {
  background-color: #795548;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-grey:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-grey {
  background-color: rgba(158, 158, 158, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-grey:after {
  background-color: #9E9E9E;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-blue-grey:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey {
  background-color: rgba(96, 125, 139, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-blue-grey:after {
  background-color: #607D8B;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-black:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-black {
  background-color: rgba(0, 0, 0, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-black:after {
  background-color: #000000;
}
.switch label input[type=checkbox]:checked:not(:disabled) ~ .lever.switch-col-white:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.switch label input[type=checkbox]:checked + .lever.switch-col-white:after {
  background-color: #ffffff;
}

.dark-cell {
  background-color: #2f2a2a;
  color: white;
  font-weight: 500;
  padding: 10px 10px 10px 10px;
}

.error-message {
  display: none;
  color: #fb3a3a;
}

.header-sort-link {
  color: #FFFFFF;
}

.active-sort {
  color: #fdb714;
}

.pagination-button {
  font-size: 20px;
}

.form-section-title {
  font-weight: bold;
  color: #333;
  width: 100%;
  border: none;
  background-color: transparent;
}

.form-indented-input + p.help-text {
  margin-left: 50px !important;
}

label:has(+ input[type=checkbox].form-indented-input) {
  margin-left: 20px;
}

.select-label {
  margin-right: 5px;
}

.select2-selection__rendered {
  padding-left: 0 !important;
}

.select2-selection__arrow {
  margin-top: 5px;
}

.select2-selection--single {
  border: 1px solid #ced4da !important;
}

.select2-search__field {
  border-radius: 0.25rem;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.collapsable-clickable {
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.centered-container {
  display: flex;
  justify-content: center;
}

.global-container {
  background-color: #f9f8f8;
  padding: 10px;
  height: 100%;
}

.halifax-text {
  display: inline-block;
  color: #2C4373;
}

hr.orange-splitter {
  border-color: #fdb714;
  border-width: 2px;
}

.standard-text {
  color: black;
  font-weight: 400;
}

.standard-input {
  border: 1px solid #ccc !important;
  padding: 5px;
}

.dropdown-toggle {
  min-height: 37px;
}

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*******************
/*Top bar
*******************/
.topbar {
  background: #ffffff;
}
.topbar .navbar-header {
  background: #f9f8f8;
}
.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
  color: #7f7373 !important;
}

/*******************
/*General Elements
*******************/
a {
  color: #fdb714;
}
a.link, a:hover, a:focus {
  color: #dc9b02;
}

.bg-theme {
  background-color: #f7921e !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f7921e;
  border-color: #f7921e;
}

.right-sidebar .rpanel-title {
  background: #f7921e;
}

.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-left: 4px solid #f7921e;
}

.text-themecolor {
  color: #f7921e !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #f7921e;
  color: #f7921e;
}
.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #f7921e;
}

[type=radio]:checked + label:after {
  border: 2px solid #fdb714;
  background-color: #fdb714;
}

/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar, .card-no-border .sidebar-nav {
  background: #231f20;
}

.mini-sidebar .sidebar-nav {
  background: transparent;
}

.sidebar-nav {
  background: #231f20;
}
.sidebar-nav ul li a {
  color: #e2ded7;
}
.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
  color: #fdb714;
}
.sidebar-nav ul li a.active {
  color: #fdb714;
}
.sidebar-nav ul li.nav-small-cap {
  color: #9e9c97;
}
.sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
  color: #e2ded7;
  background: transparent !important;
  border: 1px solid #fdb714 !important;
}
.sidebar-nav > ul > li.active > a i, .sidebar-nav > ul > li.active:hover > a i {
  color: #e2ded7;
}
.sidebar-nav > ul > li.active > a:after, .sidebar-nav > ul > li.active:hover > a:after {
  border-color: #e2ded7;
}
.sidebar-nav > ul > li > a.active i, .sidebar-nav > ul > li > a:hover i {
  color: #fdb714;
}
.sidebar-nav > ul > li > a i {
  color: #e2ded7;
}
.sidebar-nav > ul > li > a.active {
  font-weight: 400;
  background: #e2ded7;
  color: #f7921e;
}

@media only screen and (min-width :576px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    background: #141212;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    background: #fdb714 !important;
    border-color: #f7921e !important;
  }
}
.card-no-border .sidebar-footer {
  background: #141212;
}
.card-no-border .sidebar-footer a {
  color: #e2ded7;
}

.page-wrapper {
  background-color: #f9f8f8;
}

/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_app.scss.
*/
.btn-primary,
.btn-primary.disabled {
  background-color: #fdb714 !important;
  border-color: #fdb714 !important;
  box-shadow: none;
}

.btn-secondary,
.btn-secondary.disabled {
  background: #727b84;
  border: 1px solid #727b84;
  color: #ffffff;
  box-shadow: none;
}
.btn-secondary.active, .btn-secondary.disabled.active, .btn-secondary.disabled:focus, .btn-secondary.disabled:hover, .btn-secondary:focus, .btn-secondary:hover,
.btn-secondary.disabled.active,
.btn-secondary.disabled.disabled.active,
.btn-secondary.disabled.disabled:focus,
.btn-secondary.disabled.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:hover {
  opacity: 1;
  background-color: #666e76;
  border-color: #666e76;
  box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.12);
}

.btn-default,
.btn-default.disabled {
  background: transparent;
  border: 1px solid #67757c;
  color: #67757c;
}
.btn-default.active, .btn-default.disabled.active, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default:focus, .btn-default:hover,
.btn-default.disabled.active,
.btn-default.disabled.disabled.active,
.btn-default.disabled.disabled:focus,
.btn-default.disabled.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:hover {
  opacity: 1;
  background-color: #5b686e;
  border-color: #5b686e;
  box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.12);
  color: #ffffff;
}

.btn-link,
.btn-link.disabled {
  color: #fdb714;
}
.btn-link.active, .btn-link.disabled.active, .btn-link.disabled:focus, .btn-link.disabled:hover, .btn-link:focus, .btn-link:hover,
.btn-link.disabled.active,
.btn-link.disabled.disabled.active,
.btn-link.disabled.disabled:focus,
.btn-link.disabled.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:hover {
  color: #dc9b02;
}

.list-search-bar {
  padding-bottom: 10px;
}

.form-control[type=checkbox] {
  width: 1rem;
  height: 1rem;
  min-height: 1rem;
  margin-top: 10px;
  left: auto;
  opacity: 1;
}

.col-form-label {
  width: auto;
}

.navbar-header .navbar-brand > span img {
  width: 110px;
}
.navbar-header .navbar-brand > b img {
  width: 30px;
}

/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_sidebar.scss.
*/
.left-sidebar .sidebar-nav ul li a {
  font-size: 0.8rem;
  padding-left: 10px;
  transition: none;
}
.left-sidebar .sidebar-nav ul li a > i {
  float: left;
}
.left-sidebar .sidebar-nav ul li a > i + span {
  display: inline-block;
  white-space: normal;
  vertical-align: sub;
  line-height: 1.4;
}
.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
  width: 300px;
}

.mini-sidebar .left-sidebar .sidebar-nav ul li a > i + span.hide-menu {
  display: none;
}

.left-sidebar .sidebar-nav ul li > ul + ul {
  padding-top: 0;
}
.left-sidebar .sidebar-nav ul li.nav-devider {
  background: #4a4242;
}
.mini-sidebar .sidebar-nav #sidebarnav > li > ul {
  width: 240px;
}

.page-titles {
  padding-bottom: 15px;
}
.page-titles h2 {
  font-size: 1em;
  line-height: 1.3;
  margin-bottom: 0.2em;
}
@media only screen and (min-width :576px) {
  .page-titles h2 {
    font-size: 1.1em;
  }
}
@media only screen and (min-width :768px) {
  .page-titles h2 {
    font-size: 1.2em;
  }
}
@media only screen and (min-width :1170px) {
  .page-titles h2 {
    font-size: 1.3em;
  }
}
.page-titles .breadcrumb {
  font-size: 0.8rem;
}
@media only screen and (min-width :576px) {
  .page-titles .breadcrumb {
    font-size: 0.9rem;
  }
}
.page-titles #page-actions a {
  font-size: 0.85rem;
}
@media only screen and (min-width :992px) {
  .page-titles #page-actions a {
    font-size: 0.95rem;
  }
}
.page-titles #page-actions a > i {
  vertical-align: middle;
  font-size: 1.2rem;
}

.language-picker {
  position: absolute;
  right: 15px;
  width: 200px;
}

/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_pages.scss.
*/
:root {
  --data-category-a-color: #8BC77A;
  --data-category-b-color: #F8AD56;
  --data-category-c-color: #F95E55;
  --data-alternate-color: #0085B2;
}

.page-wrapper {
  padding-bottom: 0;
}

@media only screen and (max-width :1169px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-fluid .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/*******************
 Topbar
*******************/
.topbar .nav-item form {
  margin-top: 15px;
}
@media only screen and (min-width :576px) {
  .topbar .nav-item form {
    margin-right: 15px;
  }
}
.topbar .nav-item form#organizationForm, .topbar .nav-item form#groupForm, .topbar .nav-item form#impersonificationForm {
  width: 30vw;
  max-width: 250px;
}
@media only screen and (max-width :575px) {
  .topbar .nav-item form#organizationForm, .topbar .nav-item form#groupForm, .topbar .nav-item form#impersonificationForm {
    width: 160px;
  }
}
.topbar .nav-item form#organizationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li, .topbar .nav-item form#groupForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li, .topbar .nav-item form#impersonificationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li {
  font-size: 0.9rem;
}
.topbar .nav-item form#organizationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark, .topbar .nav-item form#groupForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark, .topbar .nav-item form#impersonificationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark {
  top: 50%;
  transform: translateY(-50%);
}
.topbar .nav-item form#organizationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark:after, .topbar .nav-item form#groupForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark:after, .topbar .nav-item form#impersonificationForm .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark:after {
  border-width: 0 0.15em 0.15em 0;
}
.topbar .nav-item .profile-pic {
  font-size: 1.8rem;
}
.topbar .nav-item ul.dropdown-user {
  width: 300px;
}
.topbar .nav-item ul.dropdown-user li .dw-user-box .u-img {
  text-align: center;
}
.topbar .nav-item ul.dropdown-user li .dw-user-box .u-img .mdi {
  font-size: 4rem;
  line-height: 1;
}
.topbar .nav-item ul.dropdown-user li h6 {
  font-size: 0.75rem;
  padding: 0 15px 0;
  color: #9e9c97;
}
.topbar .nav-item ul.dropdown-user li ul {
  padding: 0;
}
.topbar .nav-item ul.dropdown-user li.organization-list ul > li button {
  display: block;
  width: 100%;
  padding: 8px 15px;
  text-align: left;
  color: #67757c;
  opacity: 1;
}
.topbar .nav-item ul.dropdown-user li.organization-list ul > li button:hover {
  background-color: #f2f4f8;
  color: #f7921e;
}
.topbar .nav-item ul.dropdown-user li.organization-list ul > li button i {
  position: absolute;
  right: 15px;
  font-size: 1.3em;
  color: #21CB61;
}
.topbar .nav-item ul.dropdown-user li.organization-list ul > li.active button {
  cursor: text;
}
.topbar .nav-item ul.dropdown-user li.organization-list ul > li.active button:hover {
  background-color: transparent;
  color: #67757c;
}

#languageForm {
  margin: 15px;
}

/*******************
Progress bar
******************/
.progress-animated {
  -webkit-animation-duration: 2s;
  -webkit-transition: 2s all;
  animation-duration: 2s;
  transition: 2s all;
}

/*******************
Page Filters
******************/
#page-filters {
  font-size: 0.85rem;
}
@media only screen and (min-width :1170px) {
  #page-filters {
    font-size: 0.9rem;
  }
}
@media only screen and (min-width : 1500px) {
  #page-filters {
    font-size: 1rem;
  }
}
#page-filters form.form-horizontal .form-group-wrapper .form-control {
  font-size: 1em;
  width: 100%;
}
#page-filters form.form-horizontal .form-group-wrapper .form-control.bootstrap-select > .btn-light {
  background-color: #ffffff;
  border: 1px solid #ced4da;
}
@media only screen and (min-width :768px) {
  #page-filters .card-body {
    text-align: right;
  }
  #page-filters form.form-horizontal .form-group-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  #page-filters form.form-horizontal .form-group-wrapper .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  #page-filters form.form-horizontal .form-group-wrapper .form-group > label {
    margin: 0 0.5rem 0 0;
    white-space: nowrap;
  }
}

.text-group-a {
  color: #8BC77A;
}

.text-group-b {
  color: #F8AD56;
}

.text-group-c {
  color: #F95E55;
}

.text-average {
  color: #0085B2;
}

.col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width : 1500px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
#toast-container {
  position: fixed;
  top: 10px;
  z-index: 999999;
  pointer-events: none;
  max-width: 350px;
}
#toast-container.toast-center {
  right: 0;
  left: 0;
  margin: 0 auto;
}

.toast {
  pointer-events: auto;
  padding: 15px 15px 15px 50px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}
.toast:not(:last-child) {
  margin-bottom: 10px;
}
.toast.showing, .toast.show {
  opacity: 0.75;
}
.toast:before {
  content: "\f028";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 13px;
  font: normal normal normal 24px/1 "Material Design Icons";
}
.toast .toast-title {
  font-weight: 500;
  font-size: 0.9rem;
}
.toast .toast-title p {
  margin-bottom: 5px;
  width: 90%;
  display: inline-block;
}
.toast .toast-body {
  padding: 0 15px 0 0;
  /*margin-top: -5px;*/
  line-height: 1.3;
  font-weight: 400;
  font-size: 0.85rem;
}

.toast-error {
  background-color: #dc3545;
}
.toast-error:before {
  content: "\f029";
}

.toast-info {
  background-color: #1DA6F8;
}
.toast-info:before {
  content: "\f028";
}

.toast-success {
  background-color: #21CB61;
}
.toast-success:before {
  content: "\f5e0";
}

.toast-warning {
  background-color: #DCAA34;
}
.toast-warning:before {
  content: "\f026";
}

form label {
  color: #9e9c97;
}
form .form-control-feedback .errorlist {
  margin: 0;
  padding: 0;
}
form .form-control-feedback .errorlist li {
  font-size: 0.85em;
  color: #dc3545;
}

.page-wrapper.small .form-control {
  font-size: 0.85rem;
}

.modal form .form-group {
  margin-bottom: 15px;
}
.modal form .formset label {
  margin-top: 5px;
}
.modal form .help-text {
  margin-left: 30px;
  font-size: 0.9rem;
}

.bootstrap-select div.dropdown-menu ul.dropdown-menu li {
  font-size: 0.9rem;
}
.bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item.active, .bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item:active {
  background-color: #02AEF0;
}
.bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark {
  top: 50%;
  transform: translateY(-50%);
}
.bootstrap-select div.dropdown-menu ul.dropdown-menu li .dropdown-item .check-mark:after {
  border-width: 0 0.15em 0.15em 0;
  color: #21CB61;
}

.card,
.card-no-border .card {
  border-radius: 0;
}
.card .card-header,
.card-no-border .card .card-header {
  position: relative;
}
.card .card-header .card-header-action,
.card-no-border .card .card-header .card-header-action {
  position: absolute;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: translateY(50%);
}
.card .card-header .card-header-action > *,
.card-no-border .card .card-header .card-header-action > * {
  margin-left: 10px;
}
.card h3.card-title,
.card-no-border .card h3.card-title {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 0.75em;
}
@media only screen and (min-width :576px) {
  .card h3.card-title,
.card-no-border .card h3.card-title {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width :992px) {
  .card h3.card-title,
.card-no-border .card h3.card-title {
    font-size: 1.2rem;
  }
}
.card .spec,
.card-no-border .card .spec {
  font-weight: 300;
  font-size: 2rem;
  margin: 0;
}
@media only screen and (min-width :576px) {
  .col-sm-4 .spec {
    font-size: 1.4rem;
  }
}
.card .spec > span,
.card-no-border .card .spec > span {
  display: block;
  margin-top: 0.5em;
  font-weight: 400;
  font-size: 0.5em;
  color: #9e9c97;
}
.card.card-listing .card-header,
.card-no-border .card.card-listing .card-header {
  background-color: #231f20;
}
.card.card-listing .card-header h3.card-title,
.card-no-border .card.card-listing .card-header h3.card-title {
  color: #ffffff;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 1rem;
}
.card.card-listing .card-body .table thead th,
.card-no-border .card.card-listing .card-body .table thead th {
  font-size: 0.9rem;
  font-weight: 400;
  color: #9e9c97;
}
.card-listing .card-body .table-responsive .table thead th {
  white-space: nowrap;
}

.card.card-listing .card-body .table thead th.colspan-header,
.card-no-border .card.card-listing .card-body .table thead th.colspan-header {
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
}
.card.card-listing .card-body .table tbody td,
.card-no-border .card.card-listing .card-body .table tbody td {
  font-size: 0.9rem;
}
.card-no-border .xlarge .card.card-listing .card-body .table tbody td, .card.card-listing .card-body .table tbody td {
  font-size: 1rem;
}

.card.card-listing .card-body .table tbody td.actions,
.card-no-border .card.card-listing .card-body .table tbody td.actions {
  min-width: 100px;
  text-align: right;
}

.account #wrapper {
  position: relative;
  height: 100vh;
}
.account #wrapper .account-box {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.account #wrapper .account-box #logo {
  max-width: 210px;
}
.account #wrapper .account-box .card {
  border: none;
  border-radius: 0;
}
.account .toast {
  box-shadow: 0 0 12px #111;
}

.chart-legend {
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  width: 100%;
}
.chart-legend button {
  display: flex;
  border: 1px solid;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: transparent;
  padding: 0 5px 0 0;
  margin: 0;
  text-align: left;
}
.chart-legend button + button {
  margin-left: 15px;
}
.chart-legend button.client-category-a {
  color: #8BC77A;
}
.chart-legend button.client-category-a.active {
  background-color: #8BC77A;
}
.chart-legend button.client-category-a > span {
  background-color: #8BC77A;
}
.chart-legend button.client-category-b {
  color: #F8AD56;
}
.chart-legend button.client-category-b.active {
  background-color: #F8AD56;
}
.chart-legend button.client-category-b > span {
  background-color: #F8AD56;
}
.chart-legend button.client-category-c {
  color: #F95E55;
}
.chart-legend button.client-category-c.active {
  background-color: #F95E55;
}
.chart-legend button.client-category-c > span {
  background-color: #F95E55;
}
.chart-legend button.client-category-all {
  border: none;
  color: #02AEF0;
  font-weight: 300;
}
.chart-legend button.client-category-all.active {
  visibility: hidden;
}
.chart-legend button > span {
  display: inline-block;
  width: 1.5em;
  line-height: 1.5em;
  margin-right: 5px;
  text-align: center;
  border-right: 1px solid #ffffff;
  color: #ffffff;
}
.chart-legend button.active {
  color: #ffffff;
}

.sales-distribution-by-client-category {
  font-size: 0.8rem;
}
@media only screen and (min-width :576px) and (max-width :767px) {
  .sales-distribution-by-client-category {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width :768px) and (max-width :991px) {
  .sales-distribution-by-client-category {
    font-size: 0.7rem;
  }
}
@media only screen and (min-width :992px) and (max-width :1169px) {
  .sales-distribution-by-client-category {
    font-size: 0.85rem;
  }
}
@media only screen and (min-width : 1500px) {
  .sales-distribution-by-client-category {
    font-size: 1vw;
  }
}
@media only screen and (min-width :1170px) and (max-width :1499px) {
  .mini-sidebar .sales-distribution-by-client-category {
    font-size: 0.95rem;
  }
}

.sales-distribution-by-client-category .client-category {
  position: relative;
  margin-top: 1em;
}
.sales-distribution-by-client-category .client-category .client-category-inner {
  display: flex;
  justify-content: space-between;
}
.sales-distribution-by-client-category .client-category .client-category-inner .client-category-title {
  display: inline-block;
  color: white;
  text-align: center;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  line-height: 1.5em;
  font-size: 1.3em;
}
.sales-distribution-by-client-category .client-category .client-category-inner .sales-data {
  text-align: right;
}
.sales-distribution-by-client-category .client-category .client-category-inner .clients-data {
  text-align: left;
}
.sales-distribution-by-client-category .client-category .client-category-inner .sales-data, .sales-distribution-by-client-category .client-category .client-category-inner .clients-data {
  width: 45%;
}
@media only screen and (min-width : 1500px) {
  .sales-distribution-by-client-category .client-category .client-category-inner .sales-data, .sales-distribution-by-client-category .client-category .client-category-inner .clients-data {
    width: 44%;
  }
}
@media only screen and (min-width :1170px) {
  .mini-sidebar .sales-distribution-by-client-category .client-category .sales-data,
.mini-sidebar .sales-distribution-by-client-category .client-category .clients-data {
    width: 43% !important;
  }
}
.sales-distribution-by-client-category .client-category .client-category-inner .data-percent, .sales-distribution-by-client-category .client-category .client-category-inner .data-value {
  line-height: 1;
  font-size: 0.9em;
}
.sales-distribution-by-client-category .client-category .client-category-inner .data-percent {
  font-weight: 500;
}
.sales-distribution-by-client-category .client-category .client-category-inner .progress {
  border-radius: 0;
  height: 8px;
}
.sales-distribution-by-client-category .client-category .average-data {
  position: relative;
  margin-top: 0.5em;
  min-height: 1em;
  text-align: center;
  font-size: 0.8em;
  font-weight: 400;
}
.sales-distribution-by-client-category .client-category .average-data:before {
  content: " ";
  position: absolute;
  top: 55%;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid #CCC;
}
.sales-distribution-by-client-category .client-category .average-data .average-data-inner {
  display: inline-block;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 8px;
  color: #0085B2;
}
.sales-distribution-by-client-category .client-category .average-data .average-data-inner .average-symbol {
  width: 0.8em;
  vertical-align: baseline;
}
.sales-distribution-by-client-category .client-category .average-data .average-data-inner .average-symbol path {
  fill: #0085B2;
}
.sales-distribution-by-client-category #client-category-a .progress-bar, .sales-distribution-by-client-category #client-category-a .client-category-title {
  background-color: #8BC77A;
}
.sales-distribution-by-client-category #client-category-a .average-data:before {
  border-top-color: #8BC77A;
}
.sales-distribution-by-client-category #client-category-b .progress-bar, .sales-distribution-by-client-category #client-category-b .client-category-title {
  background-color: #F8AD56;
}
.sales-distribution-by-client-category #client-category-b .average-data:before {
  border-top-color: #F8AD56;
}
.sales-distribution-by-client-category #client-category-c .progress-bar, .sales-distribution-by-client-category #client-category-c .client-category-title {
  background-color: #F95E55;
}
.sales-distribution-by-client-category #client-category-c .average-data:before {
  border-top-color: #F95E55;
}

/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.css-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
}

.css-bar:after,
.css-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: light;
  color: #a1a2a3;
}

.css-bar:after {
  content: attr(data-label);
  background-color: #ffffff;
  font-size: 0.9rem;
  z-index: 19; /* With a value over 19, the percentages circles in a market segment appears over the menu on the left when we open it */
  overflow: hidden;
}

.css-bar > img {
  z-index: 102;
}

.css-bar:after,
.css-bar > img {
  width: 70px;
  height: 70px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 70px;
}

.css-bar.css-bar-0 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(90deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-5 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(108deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-10 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(126deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-15 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(144deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-20 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(162deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-25 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(180deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-30 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(198deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-35 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(216deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-40 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(234deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-45 {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(252deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-50 {
  background-image: linear-gradient(270deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-55 {
  background-image: linear-gradient(288deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-60 {
  background-image: linear-gradient(306deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-65 {
  background-image: linear-gradient(324deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-70 {
  background-image: linear-gradient(342deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-75 {
  background-image: linear-gradient(360deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-80 {
  background-image: linear-gradient(378deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-85 {
  background-image: linear-gradient(396deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-90 {
  background-image: linear-gradient(414deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-95 {
  background-image: linear-gradient(432deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar.css-bar-100 {
  background-image: linear-gradient(450deg, #aa7701 50%, transparent 50%, transparent), linear-gradient(270deg, #aa7701 50%, #ffffff 50%, #ffffff);
}

.css-bar-primary.css-bar-0 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(90deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-5 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(108deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-10 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(126deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-15 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(144deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-20 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(162deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-25 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(180deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-30 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(198deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-35 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(216deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-40 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(234deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-45 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(252deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-50 {
  background-image: linear-gradient(270deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-55 {
  background-image: linear-gradient(288deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-60 {
  background-image: linear-gradient(306deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-65 {
  background-image: linear-gradient(324deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-70 {
  background-image: linear-gradient(342deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-75 {
  background-image: linear-gradient(360deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-80 {
  background-image: linear-gradient(378deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-85 {
  background-image: linear-gradient(396deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-90 {
  background-image: linear-gradient(414deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-95 {
  background-image: linear-gradient(432deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.css-bar-primary.css-bar-100 {
  background-image: linear-gradient(450deg, #fdb714 50%, transparent 50%, transparent), linear-gradient(270deg, #fdb714 50%, #fff5de 50%, #fff5de);
}

.chart-negative.css-bar-0 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(90deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-5 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(108deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-10 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(126deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-15 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(144deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-20 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(162deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-25 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(180deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-30 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(198deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-35 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(216deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-40 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(234deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-45 {
  background-image: linear-gradient(90deg, #f6cdd1 50%, transparent 50%, transparent), linear-gradient(252deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-50 {
  background-image: linear-gradient(270deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-55 {
  background-image: linear-gradient(288deg, #dc3545 50%, transparent 0, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 0, #f6cdd1);
}

.chart-negative.css-bar-60 {
  background-image: linear-gradient(306deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-65 {
  background-image: linear-gradient(324deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-70 {
  background-image: linear-gradient(342deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-75 {
  background-image: linear-gradient(360deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-80 {
  background-image: linear-gradient(378deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-85 {
  background-image: linear-gradient(396deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-90 {
  background-image: linear-gradient(414deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-95 {
  background-image: linear-gradient(432deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.chart-negative.css-bar-100 {
  background-image: linear-gradient(450deg, #dc3545 50%, transparent 50%, transparent), linear-gradient(270deg, #dc3545 50%, #f6cdd1 50%, #f6cdd1);
}

.css-bar-adaptative.css-bar-0 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(90deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-5 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(108deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-10 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(126deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-15 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(144deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-20 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(162deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-25 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(180deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-30 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(198deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-35 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(216deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-40 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(234deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-45 {
  background-image: linear-gradient(90deg, #fff5de 50%, transparent 50%, transparent), linear-gradient(252deg, #dc3545 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-50 {
  background-image: linear-gradient(270deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-55 {
  background-image: linear-gradient(288deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-60 {
  background-image: linear-gradient(306deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-65 {
  background-image: linear-gradient(324deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-70 {
  background-image: linear-gradient(342deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-75 {
  background-image: linear-gradient(360deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-80 {
  background-image: linear-gradient(378deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-85 {
  background-image: linear-gradient(396deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-90 {
  background-image: linear-gradient(414deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-95 {
  background-image: linear-gradient(432deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-adaptative.css-bar-100 {
  background-image: linear-gradient(450deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #fff5de 50%, #fff5de);
}

.css-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
  position: relative;
}

.css-bar-lg:after,
.css-bar-lg > img {
  width: 90px;
  height: 90px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 90px;
}

.css-bar-lg > img {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.css-bar > i {
  width: 70px;
  height: 70px;
  background: #fff;
  line-height: 70px;
  position: absolute;
  border-radius: 100%;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  left: 0px;
  right: 0px;
  z-index: 120;
  font-size: 27px;
  color: #a6b7bf;
}

.css-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}

.css-bar-sm:after,
.css-bar-sm > img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 40px;
}

.css-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}

.css-bar-xs:after,
.css-bar-xs > img {
  width: 24px;
  height: 24px;
  margin-left: 3px;
  margin-top: 3px;
  line-height: 21px;
}

.css-bar {
  background-clip: content-box;
}

.venturi-tabs {
  width: 100%;
}
@media only screen and (max-width :767px) {
  .venturi-tabs {
    display: block;
  }
  .venturi-tabs .tabs-vertical {
    border-right: none;
    display: block;
  }
  .venturi-tabs .tabs-vertical .nav-item .nav-link,
.venturi-tabs .tabs-vertical .nav-item .nav-link.active {
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    color: #2f2a2a;
  }
}
.venturi-tabs .tabs-vertical {
  border-right: 0;
}
@media only screen and (min-width :768px) and (max-width :991px) {
  .venturi-tabs .tabs-vertical {
    width: 40%;
  }
}
@media only screen and (min-width :992px) {
  .venturi-tabs .tabs-vertical {
    width: 35%;
  }
}
@media only screen and (min-width: 1320px) {
  .venturi-tabs .tabs-vertical {
    width: 30%;
  }
}
@media only screen and (min-width : 1500px) {
  .venturi-tabs .tabs-vertical {
    width: 28%;
  }
}
@media only screen and (min-width :1170px) {
  .mini-sidebar .venturi-tabs .tabs-vertical {
    width: 30% !important;
  }
}
@media only screen and (min-width: 1320px) {
  .mini-sidebar .venturi-tabs .tabs-vertical {
    width: 25% !important;
  }
}
@media only screen and (min-width : 1500px) {
  .mini-sidebar .venturi-tabs .tabs-vertical {
    width: 25% !important;
  }
}

.venturi-tabs .tabs-vertical .nav-item .nav-link {
  margin: 0;
  padding: 0;
  border-right: 2px solid rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-out;
}
.venturi-tabs .tabs-vertical .nav-item .nav-link .card {
  margin: 0;
  opacity: 0.7;
  border-radius: 0;
  border-right: 0;
  transition: all 0.2s ease-out;
}
@media only screen and (min-width :576px) {
  .venturi-tabs .tabs-vertical .nav-item .nav-link .card .card-body {
    padding: 0.75rem;
  }
}
@media only screen and (min-width : 1500px) {
  .venturi-tabs .tabs-vertical .nav-item .nav-link .card .card-body {
    padding: 1.25rem;
  }
}
.venturi-tabs .tabs-vertical .nav-item .nav-link,
.venturi-tabs .tabs-vertical .nav-item .nav-link.active,
.venturi-tabs .tabs-vertical .nav-item .nav-link:hover {
  color: #3c3636;
  cursor: pointer;
  margin-right: 0;
}
.venturi-tabs .tabs-vertical .nav-item .nav-link.active .card,
.venturi-tabs .tabs-vertical .nav-item .nav-link:hover .card {
  opacity: 1;
}
.venturi-tabs .tabs-vertical .nav-item .nav-link:hover:not(.active) {
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}
.venturi-tabs .tab-content {
  display: block;
  padding: 0;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
}
@media only screen and (min-width :768px) {
  .venturi-tabs .tab-content {
    display: table-cell;
  }
}
.venturi-tabs .tab-content .tab-pane > .card {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
@media only screen and (min-width :768px) and (max-width :991px) {
  .venturi-tabs .tab-content .tab-pane > .card .spec {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width :1170px) and (max-width :1499px) {
  .venturi-tabs .tab-content .tab-pane > .card .spec {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width : 1500px) {
  .venturi-tabs .tab-content .tab-pane > .card .spec {
    font-size: 1.8rem;
  }
}

/*
This stylesheet overwrites some CSS rules of the Bootstrap Table library (see node_modules/bootstrap-table/).
*/
.bootstrap-table.fullscreen {
  padding: 1rem;
}
.bootstrap-table .table {
  border-style: hidden;
}
.bootstrap-table .table thead th,
.bootstrap-table .table tbody td {
  font-size: 0.9rem !important;
}
.bootstrap-table .table thead th {
  vertical-align: top !important;
}
.bootstrap-table .table thead.thead-dark th {
  background-color: #231f20 !important;
}
.bootstrap-table .table thead.thead-dark th .filter-control {
  margin-bottom: 5px;
}
.bootstrap-table .table thead.thead-dark th .filter-control select {
  background-color: #4f4747;
  color: #ffffff;
  font-size: 0.8rem;
  border-color: #4f4747;
  min-height: unset;
}
.bootstrap-table .table tbody tr.expanded, .bootstrap-table .table tbody tr.detail-view {
  border-width: 2px;
  border-style: solid;
  background-color: #ffffff;
}
.bootstrap-table .table tbody tr.expanded td, .bootstrap-table .table tbody tr.detail-view td {
  border-top: none;
}
.bootstrap-table .table tbody tr.expanded {
  border-top-color: #f7921e !important;
  border-bottom-width: 0;
}
.bootstrap-table .table tbody tr.expanded td, .bootstrap-table .table tbody tr.expanded a {
  font-weight: 500;
}
.bootstrap-table .table tbody tr.detail-view {
  border-top-width: 0;
}
.bootstrap-table .table tbody tr.detail-view table tr {
  background-color: #ffffff;
}
.bootstrap-table .table tbody tr .detail-icon {
  font-size: 1.2rem;
}
.bootstrap-table .fixed-table-toolbar button .fa.mdi {
  font-family: inherit;
  font-weight: inherit;
}
.bootstrap-table .fixed-table-toolbar button .mdi {
  font-size: 1.2rem;
}
.bootstrap-table .fixed-table-toolbar .dropdown-menu > .dropdown-item {
  font-size: 0.9rem;
}
.bootstrap-table .page-item.active .page-link {
  background-color: transparent !important;
  color: #fdb714 !important;
  border-color: #fdb714 !important;
  font-weight: 500 !important;
}
.bootstrap-table .editable-click, .bootstrap-table a.editable-click, .bootstrap-table a.editable-click:hover {
  color: #3c3636 !important;
  border-bottom-color: #fdb714 !important;
}

#top-left-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.filters-container {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-color: #fdb714;
}

.btn-icon {
  padding: 0;
  width: 1.5em;
}

.btn-search-bar {
  font-size: 1.2em;
  padding: 4px 10px;
  margin-left: -1px;
  z-index: 2;
}

.criteria-form {
  margin: 0;
  padding: 0;
  width: 100%;
}

.opportunity-criteria-wrapper .criterion:nth-child(odd) {
  background-color: #fff5de;
}

.criteria-wrapper {
  color: #4B3400;
  width: 100%;
  border-bottom: 1px solid #3c3636;
}
.criteria-wrapper .criterion {
  display: flex;
}
.criteria-wrapper .criterion.visible-odd-criterion {
  background-color: #fff5de;
}
.criteria-wrapper .criterion .title {
  width: 20%;
  padding: 3px 10px;
  overflow: hidden;
  align-self: center;
}
.criteria-wrapper .criterion .title h4 {
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 0.3em;
}
.page-wrapper.xxlarge .criterion .title h4 {
  font-size: 1rem;
}
.page-wrapper.xxlarge .criterion .title h4 > i {
  display: none;
}

.criteria-wrapper .criterion .title p {
  display: none;
  font-size: 0.9rem;
  line-height: 1.2;
  height: 1.2em;
  margin-bottom: 0;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-wrapper.xxlarge .criterion .title p {
  display: block;
}

.criteria-wrapper .criterion .choice-list {
  width: 80%;
  height: 55px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-self: center;
}
.page-wrapper.xxlarge .criterion .choice-list {
  width: 75%;
  height: 65px;
}

.criteria-wrapper .criterion .choice-list .choice {
  flex-grow: 0;
  flex-basis: 20%;
  background-color: transparent;
  border: none;
  margin: 0;
  box-shadow: none;
}
.criteria-wrapper .criterion .choice-list .choice.read-only {
  pointer-events: none;
}
.criteria-wrapper .criterion .choice-list .choice.read-only a span {
  opacity: 0.6 !important;
}
.criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active, .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active {
  background-color: transparent;
}
.criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .chart, .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .chart {
  background-color: #fdb714;
  opacity: 1;
}
.criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .chart:after, .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .chart:after {
  background-color: #fdb714;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .chart.chart-negative:after, .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .chart.chart-negative:after {
  background-color: #dc3545;
}
.criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .choice-value, .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .choice-value {
  font-weight: 500;
  opacity: 1;
}
.criteria-wrapper .criterion .choice-list .choice a {
  color: #4B3400;
  transition: 0.2s all ease-out;
}
.criteria-wrapper .criterion .choice-list .choice a .chart {
  width: 43px;
  height: 43px;
  margin: 0;
  float: left;
  margin-right: 15px;
  opacity: 0.5;
  transition: 0.2s all ease-out;
}
.page-wrapper.xxlarge .criterion .choice-list .choice .chart {
  width: 50px;
  height: 50px;
}

.criteria-wrapper .criterion .choice-list .choice a .chart:after {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 0.75rem;
  color: #141212;
}
.page-wrapper.xxlarge .criterion .choice-list .choice .chart:after {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 0.9rem;
}

.page-wrapper.xxlarge .criterion .choice-list .choice .chart:after {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 0.9rem;
}

.criteria-wrapper .criterion .choice-list .choice a .chart.chart-negative {
  transform: scale(-1, 1);
}
.criteria-wrapper .criterion .choice-list .choice a .chart.chart-negative:after {
  transform: scale(-1, 1);
}
.criteria-wrapper .criterion .choice-list .choice a .choice-value-container {
  display: flex;
  height: 40px;
}
.page-wrapper.xxlarge .criterion .choice-list .choice .choice-value-container {
  height: 50px;
}

.criteria-wrapper .criterion .choice-list .choice .choice-value {
  align-self: center;
  text-align: left;
  line-height: 1.1;
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
  transition: 0.2s all ease-out;
}
.page-wrapper.xxlarge .criterion .choice-list .choice .choice-value {
  font-size: 0.9rem;
}

.page-wrapper.xxlarge .criterion .choice-list .choice .choice-value {
  font-size: 0.9rem;
}

.criteria-wrapper .criterion .choice-list .choice a {
  color: #4B3400;
  transition: 0.2s all ease-out;
}
.criteria-wrapper .criterion .choice-list .choice a:hover .chart,
.criteria-wrapper .criterion .choice-list .choice a:hover .choice-value {
  opacity: 1;
}

#criteria-list .criteria-wrapper {
  border-bottom: none;
}
.xxlarge #criteria-list .criteria-wrapper {
  padding-left: 0;
}

.criteria-total {
  display: flex;
  border-top: 1px solid #e1dddd;
}
.criteria-total .weight {
  margin-left: 9.7%;
  padding: 5px 0;
  height: 55px;
  min-width: 55px;
  width: 5%;
  background-color: #e1dddd;
  text-align: center;
}
.xxlarge #criteria-list .criteria-wrapper .criteria-total .weight {
  margin-left: 6.9%;
}

.criteria-total .weight .css-bar {
  width: 46px;
  height: 46px;
}
.criteria-total .weight .css-bar.css-bar-100 {
  background-color: #fdb714;
  background-image: none;
}
.criteria-total .weight .css-bar:after {
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 36px;
  color: #3c3636;
}
.criteria-total .message {
  align-self: center;
  padding: 0 10px;
}
.criteria-total .message .alert {
  display: inline-block;
  margin: 0;
  font-size: 0.9rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

#criteria-list .criteria-wrapper .criterion {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  padding: 0;
  border-top: 1px solid #f9f8f8;
}
#criteria-list .criteria-wrapper .criterion:nth-child(odd) {
  background-color: #ffffff;
}
#criteria-list .criteria-wrapper .criterion:hover {
  background-color: transparent;
}
#criteria-list .criteria-wrapper .criterion .type {
  font-size: 0.9rem;
  width: 10%;
  height: 55px;
  background-color: #f7921e;
  line-height: 55px;
  padding: 0 5px;
  text-align: center;
  color: #ffffff;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .type {
  font-size: 1rem;
  width: 7%;
  height: 65px;
  line-height: 65px;
}

#criteria-list .criteria-wrapper .criterion .weight {
  width: 5%;
  min-width: 55px;
  height: 55px;
  background-color: #eee;
  text-align: center;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .weight {
  height: 65px;
}

#criteria-list .criteria-wrapper .criterion .weight .css-bar {
  width: 46px;
  height: 46px;
  font-size: 12px;
  margin: 5px 0 0;
}
#criteria-list .criteria-wrapper .criterion .weight .css-bar:after {
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 36px;
  color: #3c3636;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .weight .css-bar {
  margin-top: 10px;
}

#criteria-list .criteria-wrapper .criterion .title {
  width: 18%;
}
#criteria-list .criteria-wrapper .criterion .title h4 {
  font-size: 0.8rem;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .title {
  width: 20%;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .title h4 {
  font-size: 1rem;
}

#criteria-list .criteria-wrapper .criterion .choice-list {
  width: 60%;
  align-items: center;
  background-color: #fff5de;
  padding: 0 10px;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice {
  padding-right: 5px;
  font-size: 1rem;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .choice {
  font-size: 1.1rem;
}

#criteria-list .criteria-wrapper .criterion .choice-list .choice .chart {
  float: left;
  font-size: 1em;
  width: 2em;
  height: 2em;
  margin: 0.7em 0.5em 0 0;
  opacity: 1;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice .chart:after {
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
  font-size: 0.7em;
  margin-left: 0.32em;
  margin-top: 0.3em;
  white-space: nowrap;
  z-index: 2;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice .chart.chart-negative {
  transform: scale(-1, 1);
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice .chart.chart-negative:after {
  transform: scale(-1, 1);
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .chart, #criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .chart {
  background-color: transparent;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .chart:after, #criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .chart:after {
  background-color: #ffffff;
  border: none;
  color: #a1a2a3;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled).active .choice-value, #criteria-list .criteria-wrapper .criterion .choice-list .choice:not(:disabled):not(.disabled):active .choice-value {
  font-weight: 500;
  opacity: 1;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice .choice-value-container {
  display: flex;
  height: 57px;
  overflow: hidden;
}
#criteria-list .criteria-wrapper .criterion .choice-list .choice .choice-value-container .choice-value {
  font-size: 0.75em;
  overflow: hidden;
  text-overflow: ellipsis;
}
#criteria-list .criteria-wrapper .criterion .item-actions {
  width: 10%;
  font-size: 1.2rem;
  margin-left: auto;
  align-self: center;
  text-align: right;
  padding-right: 15px;
}
.xxlarge #criteria-list .criteria-wrapper .criterion .actions {
  width: 5%;
}

.criteria-actions .button-group {
  margin-top: 1rem;
}
.page-wrapper.xxlarge .criteria-actions .button-group {
  text-align: left;
}

.standard-table .table td, .standard-table .table th {
  text-align: center;
  padding: 5px 0 5px 0;
  font-size: 1rem !important;
  vertical-align: middle;
}
.standard-table .table .thead-dark th {
  background-color: #2f2a2a;
  color: #ffffff !important;
  font-weight: 500 !important;
}
.standard-table .table tr:nth-child(even) {
  background-color: #eaeaea;
}
.standard-table .table tr > td.name {
  width: 20%;
}

.list-item {
  display: flex;
  padding: 7px;
  transition: all 0.2s ease-out;
}
.list-item:hover {
  background-color: #f2f0f0;
}
.list-item + .list-item {
  border-top: 1px solid #f2f0f0;
}
.list-item > .item-title {
  flex-grow: 1;
  margin: 0;
  font-size: 1rem;
  align-self: center;
}
.list-item .item-actions {
  align-self: center;
  font-size: 1.2rem;
  text-align: right;
}

.modal .modal-header {
  background-color: #231f20;
}
.modal .modal-header .modal-title {
  color: #ffffff;
}
.modal .modal-header .close {
  color: #fdb714;
  opacity: 1;
  text-shadow: none;
}

.rich-text-container .icon.check,
.rich-text-container .icon.cancel {
  top: 64px;
}

.ql-container {
  height: auto !important;
  font-size: 16px !important;
}

.ql-editor {
  height: auto !important;
  font-family: Inter, sans-serif;
  font-weight: normal;
  color: black;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input,
.input-container textarea {
  border: none;
  padding: 12px 75px 12px 15px; /* Right padding for the buttons */
  width: 100%;
}

.input-container .editor-container {
  padding-right: 70px;
}

.input-container .editor-container:not(.editing) {
  border: none !important;
}

.input-container .editor-container.editing {
  border-width: 0 1px 1px 1px !important;
  border-color: #ccc;
  border-style: solid;
  min-width: 530px;
}

.input-container:hover .editor-container:not(.editing),
.input-container:hover input,
.input-container:hover textarea,
.input-container input.editing:not(.is-invalid) {
  border: 1px solid #ccc !important;
}

.input-container input.is-invalid {
  border: 2px solid #dc3545 !important;
}

.input-container .icon {
  position: absolute;
  height: 28px;
  top: 50%;
  transform: translateY(-50%);
  display: none; /* Hide the icons initially */
}

.input-container .icon.edit {
  right: 8px;
  align-items: center;
}

.input-container .icon.check {
  right: 40px;
}

.input-container .icon.cancel {
  right: 8px;
}

.input-container:hover .icon.edit {
  display: flex; /* Show the edit icon */
}

textarea {
  display: block;
}