/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_variable.scss.
*/

// Theme Colors
// ==========================================================================
$black: #141212;
$white: #ffffff;
$gray-xlight: lighten($black, 90%);
$gray-light: lighten($black, 87%);
$gray-medium: lighten($black, 80%);
$topbar: $white;
$topbar-header: lighten($black, 90%);
$topbar-text: lighten($black, 40%);
$themecolor: #f7921e;
$themecolor-dark: #fdb714;
$themecolor-alt: $themecolor-dark;
$themecolor-xdark: darken($themecolor-dark, 20%);
$themecolor-xlight: lighten($themecolor-dark, 40%);
$sidebar: #231f20;
$sidebar-footer: $black;
$sidebar-text: #e2ded7;
$sidebar-icons: $sidebar-text;
$bodytext: #67757c;
$link: #02AEF0;
$bodycolor: lighten($black, 90%);
$headingtext: lighten($black, 10%);
$text-color: lighten($black, 15%);
$border-color: rgba(0, 0, 0, 0.12);
$form-control-border-color: #ced4da;
$label-color: #999999;
$table-header-dark: $sidebar;
$table-header-dark-control: lighten($black, 22%);
$table-footer-medium: lighten($sidebar, 25%);
$table-footer-dark: lighten($sidebar, 30%);

// Bootstrap Colors
// ==========================================================================
$danger: #dc3545;
$success: #21CB61;
$warning: #DCAA34;
$primary: $themecolor-alt;
$info: #1DA6F8;
$inverse: #2f3d4a;
$muted: #9e9c97;
$dark: lighten($black, 10%);
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

$light-danger: lighten($danger, 35%);
$light-success: lighten($success, 40%);
$light-warning: lighten($warning, 40%);
$light-primary: lighten($primary, 40%);
$light-info: lighten($info, 40%);
$light-inverse: lighten($inverse, 72%);
$light-megna: #e0f2f4;

$danger-dark: darken($danger, 5%);
$success-dark: darken($success, 5%);
$warning-dark: darken($warning, 5%);
$primary-dark: darken($primary, 5%);
$info-dark: darken($info, 5%) #028ee1;
$inverse-dark: darken($inverse, 5%);
$dark-transparent:rgba(0, 0, 0, 0.05);

$bg-dark: lighten($black, 10%);

// Venturi Colors
// ==========================================================================
$data-category-a-color: #8BC77A;
$data-category-b-color: #F8AD56;
$data-category-c-color: #F95E55;
$data-alternate-color: #0085B2;


// Font Weights
// ==========================================================================
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-xbold: 700;


// Custom Media Query Breakpoint
// ==========================================================================
$small-and-up: "only screen and (min-width :576px)";
$medium-and-up: "only screen and (min-width :768px)";
$large-and-up: "only screen and (min-width :992px)";
$extra-large-and-up: "only screen and (min-width :1170px)";
$extra-extra-large-and-up: "only screen and (min-width : 1500px)";

$extra-small-only: "only screen and (max-width :575px)";
$small-only: "only screen and (min-width :576px) and (max-width :767px)";
$medium-only: "only screen and (min-width :768px) and (max-width :991px)";
$large-only: "only screen and (min-width :992px) and (max-width :1169px)";
$extra-large-only: "only screen and (min-width :1170px) and (max-width :1499px)";

$extra-small-and-down: "only screen and (max-width :575px)";
$small-and-down: "only screen and (max-width :767px)";
$medium-and-down: "only screen and (max-width :991px)";
$large-and-down: "only screen and (max-width :1169px)";
$extra-large-and-down: "only screen and (max-width : 1499px)";
