/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_app.scss.
*/


// Buttons
// ==========================================================================
.btn-primary,
.btn-primary.disabled {
    background-color: #fdb714!important;
    border-color: #fdb714!important;
    box-shadow: none;
}

.btn-secondary,
.btn-secondary.disabled {
    background: $secondary;
    border: 1px solid $secondary;
    color: $white;
    box-shadow: none;
    &.active,
    &.disabled.active,
    &.disabled:focus,
    &.disabled:hover,
    &:focus,
    &:hover {
      opacity: 1;
      background-color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
      box-shadow: 0 14px 26px -12px rgba(0,0,0,.12), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.12);
    }
}

.btn-default,
.btn-default.disabled {
    background: transparent;
    border: 1px solid $bodytext;
    color: $bodytext;
    &.active,
    &.disabled.active,
    &.disabled:focus,
    &.disabled:hover,
    &:focus,
    &:hover {
      opacity: 1;
      background-color: darken($bodytext, 5%);
      border-color: darken($bodytext, 5%);
      box-shadow: 0 14px 26px -12px rgba(0,0,0,.12), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.12);
      color: $white;
    }
}


.btn-link,
.btn-link.disabled {
  color: $primary;
  &.active,
  &.disabled.active,
  &.disabled:focus,
  &.disabled:hover,
  &:focus,
  &:hover {
    color: darken($primary, 10%);
  }
}

.list-search-bar {
  padding-bottom: 10px;
}

.form-control[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  min-height: 1rem;
  margin-top: 10px;
  left: auto;
  opacity: 1;
}

.col-form-label {
  width: auto;
}
