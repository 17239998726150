.card,
.card-no-border .card {
  border-radius: 0;

  .card-header {
    position: relative;

    .card-header-action {
      position: absolute;
      right: 15px;
      bottom: 0;
      display: flex;
      align-items: center;
      transform: translateY(50%);

      & > * {
        margin-left: 10px;
      }
    }
  }

  h3.card-title {
    font-size: 1rem;
    line-height: 1.3;
    margin-bottom: 0.75em;
    @media #{$small-and-up} {
      font-size: 1.1rem;
    }
    @media #{$large-and-up} {
      font-size: 1.2rem;
    }
  }

  .spec {
    font-weight: $font-weight-light;
    font-size: 2rem;
    margin: 0;
    @media #{$small-and-up} {
      @at-root {
        .col-sm-4 .spec {
          font-size: 1.4rem;
        }
      }
    }

    & > span {
      display: block;
      margin-top: 0.5em;
      font-weight: $font-weight-normal;
      font-size: 0.5em;
      color: $muted;
    }
  }


  &.card-listing {

    .card-header {
      background-color: $table-header-dark;

      h3.card-title {
        color: $white;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        font-size: 1rem;
      }
    }

    .card-body {

      .table {

        thead th {
          font-size: 0.9rem;
          font-weight: $font-weight-normal;
          color: $muted;

          @at-root {
            .card-listing .card-body .table-responsive .table thead th {
              white-space: nowrap;
            }
          }

          &.colspan-header {
            border-bottom: 1px solid #eee;
            text-transform: uppercase;
            font-size: 0.8rem;
            text-align: center;
          }
        }

        tbody td {
          font-size: 0.9rem;

          @at-root {

            .card-no-border .xlarge .card.card-listing .card-body .table tbody td, .card.card-listing .card-body .table tbody td {
              font-size: 1rem;
            }
          }

          &.actions {
            min-width: 100px;
            text-align: right;
          }
        }
      }
    }
  }
}
