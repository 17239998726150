
.criteria-form {
  margin: 0;
  padding: 0;
  width: 100%;
}

.opportunity-criteria-wrapper {
  .criterion {
    &:nth-child(odd) {
      background-color: $themecolor-xlight;
    }
  }
}

.criteria-wrapper {
  color: #4B3400;
  width: 100%;
  border-bottom: 1px solid $text-color;

  .criterion {
    display: flex;

    &.visible-odd-criterion {
      background-color: $themecolor-xlight;
    }

    .title {
      width: 20%;
      padding: 3px 10px;
      overflow: hidden;
      align-self: center;

      h4 {
        font-size: 0.9rem;
        line-height: 1.2;
        margin-bottom: 0.3em;

        @at-root {
          .page-wrapper.xxlarge .criterion .title h4 {
            font-size: 1rem;

            & > i {
              display: none;
            }
          }
        }
      }

      p {
        display: none;
        font-size: 0.9rem;
        line-height: 1.2;
        height: 1.2em;
        margin-bottom: 0;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;

        @at-root {
          .page-wrapper.xxlarge .criterion .title p {
            display: block;
          }
        }
      }
    }

    .choice-list {
      width: 80%;
      height: 55px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-self: center;

      @at-root {
        .page-wrapper.xxlarge .criterion .choice-list {
          width: 75%;
          height: 65px;
        }
      }

      .choice {
        flex-grow: 0;
        flex-basis: 20%;
        background-color: transparent;
        border: none;
        margin: 0;
        box-shadow: none;

        &.read-only {
          pointer-events: none;

          a {
            span {
              opacity: 0.6!important;
            }
          }
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
          background-color: transparent;

            .chart {
              background-color: $themecolor-dark;
              opacity: 1;

              &:after {
                background-color: $themecolor-dark;
                border: 1px solid $white;
                color: $white;
              }

              &.chart-negative:after {
                background-color: $danger;
              }
            }

          .choice-value {
            font-weight: $font-weight-medium;
            opacity: 1;
          }
        }

        a {
          color: #4B3400;
          transition: .2s all ease-out;

          .chart {
            width: 43px;
            height: 43px;
            margin: 0;
            float: left;
            margin-right: 15px;
            opacity: .5;
            transition: .2s all ease-out;

            @at-root {
              .page-wrapper.xxlarge .criterion .choice-list .choice .chart {
                width: 50px;
                height: 50px;
              }
            }

            &:after {
              width: 32px;
              height: 32px;
              line-height: 32px;
              font-size: .75rem;
              color: $black;

              @at-root {
                .page-wrapper.xxlarge .criterion .choice-list .choice .chart:after {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  font-size: .9rem;
                }
              }

              @at-root {
                .page-wrapper.xxlarge .criterion .choice-list .choice .chart:after {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  font-size: .9rem;
                }
              }
            }

            &.chart-negative {
              transform: scale(-1, 1);

              &:after {
                transform: scale(-1, 1);
              }
            }
          }

          .choice-value-container {
            display: flex;
            height: 40px;

            @at-root {
              .page-wrapper.xxlarge .criterion .choice-list .choice .choice-value-container {
                height: 50px;
              }
            }
          }
        }

          .choice-value {
            align-self: center;
            text-align: left;
            line-height: 1.1;
            font-size: 0.8rem;
            font-weight: $font-weight-normal;
            opacity: .6;
            transition: .2s all ease-out;

            @at-root {
              .page-wrapper.xxlarge .criterion .choice-list .choice .choice-value {
                font-size: 0.9rem;
              }
            }

          @at-root {
            .page-wrapper.xxlarge .criterion .choice-list .choice .choice-value {
              font-size: 0.9rem;
            }
          }
        }

        a {
          color: #4B3400;
          transition: .2s all ease-out;

          &:hover {

            .chart,
            .choice-value {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// Specific styles for the Criteria page (criteria management).
#criteria-list {

  .criteria-wrapper {
    border-bottom: none;

    @at-root {
      .xxlarge #criteria-list .criteria-wrapper {
        padding-left: 0;
      }

      .criteria-total {
        display: flex;
        border-top: 1px solid $gray-medium;

        .weight {
          margin-left: 9.7%;
          padding: 5px 0;
          height: 55px;
          min-width: 55px;
          width: 5%;
          background-color: $gray-medium;
          text-align: center;

          @at-root {
            .xxlarge #criteria-list .criteria-wrapper .criteria-total .weight {
              margin-left: 6.9%;
            }
          }

          .css-bar {
            width: 46px;
            height: 46px;

            // Prevent a white vertical line in the middle of the chart when 100%.
            &.css-bar-100 {
              background-color: $themecolor-dark;
              background-image: none;
            }

            &:after {
              width: 36px;
              height: 36px;
              margin-left: 5px;
              margin-top: 5px;
              line-height: 36px;
              color: $text-color;
            }
          }
        }

        .message {
          align-self: center;
          padding: 0 10px;

          .alert {
            display: inline-block;
            margin: 0;
            font-size: 0.9rem;
            padding-top: 5px;
            padding-bottom: 5px;

          }
        }
      }
    }

    .criterion {
      box-shadow: 0 2px 5px rgba(0,0,0,.05);
      margin-bottom: 15px;
      padding: 0;
      border-top: 1px solid $gray-xlight;

      &:nth-child(odd) {
        background-color: $white;
      }

      &:hover {
        background-color: transparent;
      }

      .type {
        font-size: 0.9rem;
        width: 10%;
        height: 55px;
        background-color: $themecolor;
        line-height: 55px;
        padding: 0 5px;
        text-align: center;
        color: $white;

        @at-root {
          .xxlarge #criteria-list .criteria-wrapper .criterion .type {
            font-size: 1rem;
            width: 7%;
            height: 65px;
            line-height: 65px;
          }
        }
      }

      .weight {
        width: 5%;
        min-width: 55px;
        height: 55px;
        background-color: #eee;
        text-align: center;

        @at-root {
          .xxlarge #criteria-list .criteria-wrapper .criterion .weight {
            height: 65px;
          }
        }

        .css-bar {
          width: 46px;
          height: 46px;
          font-size: 12px;
          margin: 5px 0 0;

          &:after {
            width: 36px;
            height: 36px;
            margin-left: 5px;
            margin-top: 5px;
            line-height: 36px;
            color: $text-color;
          }

          @at-root {
            .xxlarge #criteria-list .criteria-wrapper .criterion .weight .css-bar {
              margin-top: 10px;
            }
          }
        }
      }

      .title {
        width: 18%;

        h4 {
          font-size: .8rem;
        }

        @at-root {
          .xxlarge #criteria-list .criteria-wrapper .criterion .title {
            width: 20%;

            h4 {
              font-size: 1rem;
            }
          }
        }
      }

      .choice-list {
        width: 60%;
        align-items: center;
        background-color: $themecolor-xlight;
        padding: 0 10px;

        .choice {
          padding-right: 5px;
          font-size: 1rem;

          @at-root {
            .xxlarge #criteria-list .criteria-wrapper .criterion .choice {
              font-size: 1.1rem;
            }
          }

          .chart {
            float: left;
            font-size: 1em;
            width: 2em;
            height: 2em;
            margin: 0.7em 0.5em 0 0;
            opacity: 1;

            &:after {
              width: 2.2em;
              height: 2.2em;
              line-height: 2.2em;
              font-size: .7em;
              margin-left: .32em;
              margin-top: .3em;
              white-space: nowrap;
              z-index: 2;
            }

            &.chart-negative {
              transform: scale(-1, 1);

              &:after {
                transform: scale(-1, 1);
              }
            }
          }

          &:not(:disabled):not(.disabled).active,
          &:not(:disabled):not(.disabled):active {

              .chart {
                background-color: transparent;

                &:after {
                  background-color: $white;
                  border: none;
                  color: #a1a2a3;
                }
              }

            .choice-value {
              font-weight: $font-weight-medium;
              opacity: 1;
            }
          }

          .choice-value-container {
            display: flex;
            height: 57px;
            overflow: hidden;

            .choice-value {
              font-size: 0.75em;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .item-actions {
        width: 10%;
        font-size: 1.2rem;
        margin-left: auto;
        align-self: center;
        text-align: right;
        padding-right: 15px;

        @at-root {
          .xxlarge #criteria-list .criteria-wrapper .criterion .actions {
            width: 5%;
          }
        }
      }
    }
  }
}

.criteria-actions {

  .button-group {
    margin-top: 1rem;

    @at-root {
      .page-wrapper.xxlarge .criteria-actions .button-group {
        text-align: left;
      }
    }
  }
}
