.page-titles {
    padding-bottom: 15px;

    h2 {
        font-size: 1em;
        line-height: 1.3;
        margin-bottom: 0.2em;

        @media #{$small-and-up} {
            font-size: 1.1em;
        }
        @media #{$medium-and-up} {
            font-size: 1.2em;
        }
        @media #{$extra-large-and-up} {
            font-size: 1.3em;
        }
    }

    .breadcrumb {
        font-size: 0.8rem;

        @media #{$small-and-up} {
            font-size: 0.9rem;
        }
    }

    #page-actions {

      a {
        font-size: 0.85rem;

        @media #{$large-and-up} {
          font-size: 0.95rem;
        }

        & > i {
          vertical-align: middle;
          font-size: 1.2rem;
        }
      }
    }
}

.language-picker {
  position: absolute;
  right:15px;
  width:200px;
}
