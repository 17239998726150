/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_pages.scss.
*/

// Chart color codes used in project.js.
:root {
    --data-category-a-color: #{$data-category-a-color};
    --data-category-b-color: #{$data-category-b-color};
    --data-category-c-color: #{$data-category-c-color};
    --data-alternate-color: #{$data-alternate-color};
}

.page-wrapper {
  padding-bottom: 0;  // Only when no footer
}

.container-fluid {

    @media #{$large-and-down} {
        padding-left: 15px;
        padding-right: 15px;

        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}



/*******************
 Topbar
*******************/
.topbar {

  .nav-item {

    form {
      margin-top: 15px;

      @media #{$small-and-up} {
        margin-right: 15px;
      }

      &#organizationForm, &#groupForm, &#impersonificationForm {
        width: 30vw;
        max-width: 250px;

        @media #{$extra-small-and-down} {
          width: 160px;
        }

        .bootstrap-select div.dropdown-menu {
          ul.dropdown-menu li {
            font-size: 0.9rem;

            .dropdown-item .check-mark {
              top: 50%;
              transform: translateY(-50%);

              &:after {
                border-width: 0 .15em .15em 0;
              }
            }
          }
        }
      }
    }

    .profile-pic {
      font-size: 1.8rem;
    }

    ul.dropdown-user {
      width: 300px;

      li {

        .dw-user-box .u-img {
          text-align: center;

          .mdi {
            font-size: 4rem;
            line-height: 1;
          }
        }

        h6 {
          font-size: 0.75rem;
          padding: 0 15px 0;
          color: $muted;
        }

        ul {
          padding: 0;
        }

        &.organization-list {

          ul > li {

            button {
              display: block;
              width: 100%;
              padding: 8px 15px;
              text-align: left;
              color: $bodytext;
              opacity: 1;

              &:hover {
                background-color: $light;
                color: $themecolor;
              }

              i {
                position: absolute;
                right: 15px;
                font-size: 1.3em;
                color: $success;
              }
            }

            &.active button {
              cursor: text;

              &:hover {
                background-color: transparent;
                color: $bodytext;
              }
            }
          }
        }
      }
    }
  }
}

#languageForm {
  margin: 15px;
}


/*******************
Progress bar
******************/
.progress-animated {
    -webkit-animation-duration: 2s;
    -webkit-transition: 2s all;
    animation-duration: 2s;
    transition: 2s all;
}



/*******************
Page Filters
******************/
#page-filters {
  font-size: 0.85rem;

  @media #{$extra-large-and-up} {
    font-size: 0.9rem;
  }
  @media #{$extra-extra-large-and-up} {
    font-size: 1rem;
  }

  form.form-horizontal .form-group-wrapper {

    .form-control {
      font-size: 1em;
      width: 100%;  // Assuming each form-control is contained in a grid column.

      &.bootstrap-select > .btn-light {
        background-color: $white;
        border: 1px solid $form-control-border-color;
      }
    }
  }

  @media #{$medium-and-up} {

    .card-body {
      text-align: right;
    }

    form.form-horizontal .form-group-wrapper {
      display: flex;
      justify-content: flex-end;

      .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        & > label {
          margin: 0 0.5rem 0 0;
          white-space: nowrap;
        }
      }
    }
  }
}
