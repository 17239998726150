.navbar-header {
    .navbar-brand {
        & > span img {
            width: 110px;
        }
        & > b img {
            width: 30px;
        }
    }
}
