.venturi-tabs {
    width: 100%;

    @media #{$small-and-down} {
        display: block;

        .tabs-vertical {
            border-right: none;
            display: block;

            .nav-item .nav-link,
            .nav-item .nav-link.active {
                border: none;
                margin: 0;
                padding: 0;
                border-radius: 0;
                color: $dark;
            }
        }
    }

    .tabs-vertical {
        border-right: 0;

        @media #{$medium-only} {
            width: 40%;
        }
        @media #{$large-and-up} {
            width: 35%;
        }
        @media only screen and (min-width : 1320px) {
            width: 30%;
        }
        @media #{$extra-extra-large-and-up} {
            width: 28%;
        }
        @at-root {
            .mini-sidebar .venturi-tabs .tabs-vertical {
                @media #{$extra-large-and-up} {
                    width: 30% !important;
                }
                @media only screen and (min-width : 1320px) {
                    width: 25% !important;
                }
                @media #{$extra-extra-large-and-up} {
                    width: 25% !important;
                }
            }
        }

        .nav-item .nav-link {
            margin: 0;
            padding: 0;
            border-right: 2px solid $border-color;
            transition: all .2s ease-out;

            .card {
                margin: 0;
                opacity: 0.7;
                border-radius: 0;
                border-right: 0;
                transition: all .2s ease-out;

                .card-body {
                    @media #{$small-and-up} {
                        padding: 0.75rem;
                    }
                    @media #{$extra-extra-large-and-up} {
                        padding: 1.25rem;
                    }
                }
            }
        }

        .nav-item .nav-link,
        .nav-item .nav-link.active,
        .nav-item .nav-link:hover {
            color: $text-color;
            cursor: pointer;
            margin-right: 0;
        }

        .nav-item .nav-link.active,
        .nav-item .nav-link:hover {

            .card {
                opacity: 1;
            }
        }

        .nav-item .nav-link:hover:not(.active) {
            border-right: 2px solid $border-color;
        }
    }

    .tab-content {
        display: block;
        padding: 0;
        background-color: $white;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-left: none;

        @media #{$medium-and-up} {
            display: table-cell;
        }

        .tab-pane > .card {
            border: none;
            border-radius: 0;
            box-shadow: none;

            .spec {

                @media #{$medium-only} {
                    font-size: 1.2rem;
                }
                @media #{$extra-large-only} {
                    font-size: 1.6rem;
                }
                @media #{$extra-extra-large-and-up} {
                    font-size: 1.8rem;
                }
            }
        }
    }
}
