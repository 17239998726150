.list-item {
  display: flex;
  padding: 7px;
  transition: all .2s ease-out;

  &:hover {
    background-color: $gray-light;
  }

  & + .list-item {
    border-top: 1px solid $gray-light;
  }

  & > .item-title {
    flex-grow: 1;
    margin: 0;
    font-size: 1rem;
    align-self: center;
  }

  .item-actions {
    align-self: center;
    font-size: 1.2rem;
    text-align: right;
  }
}
