/*
This stylesheet overwrites some CSS rules of the Bootstrap Table library (see node_modules/bootstrap-table/).
*/

.bootstrap-table {

  &.fullscreen {
    padding: 1rem;
  }

  .table {
    border-style: hidden;

    thead th,
    tbody td {
      font-size: 0.9rem !important;
    }

    thead {

      th {
        vertical-align: top !important;
      }

      &.thead-dark th {
        background-color: $table-header-dark !important;

        .filter-control {
          margin-bottom: 5px;

          select {
            background-color: $table-header-dark-control;
            color: $white;
            font-size: 0.8rem;
            border-color: $table-header-dark-control;
            min-height: unset;
          }
        }
      }
    }

    tbody tr {

      &.expanded,
      &.detail-view {
        border-width: 2px;
        border-style: solid;
        background-color: $white;

        td {
          border-top: none;
        }
      }

      &.expanded {
        border-top-color: $themecolor !important;
        border-bottom-width: 0;

        td, a {
          font-weight: $font-weight-medium;
        }
      }

      &.detail-view {
        border-top-width: 0;

        table tr {
          background-color: $white;
        }
      }

      .detail-icon {
        font-size: 1.2rem;
      }
    }
  }

  // Tool Bar
  .fixed-table-toolbar {

    // Reseting font-awesome styling because we rather use Material Design Icons.
    button {
      .fa.mdi {
        font-family: inherit;
        font-weight: inherit;
      }

      .mdi {
        font-size: 1.2rem;
      }
    }

    .dropdown-menu > .dropdown-item {
      font-size: 0.9rem;
    }
  }

  // Pagination
  .page-item.active .page-link {
    background-color: transparent !important;
    color: $themecolor-dark !important;
    border-color: $themecolor-dark !important;
    font-weight: $font-weight-medium !important;
  }


  // Editable extension
  .editable-click, a.editable-click, a.editable-click:hover {
    color: $text-color !important;
    border-bottom-color: $themecolor-dark !important;
  }
}

#top-left-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.filters-container {
  display: flex;
  gap: 5px;
  align-items: baseline;
}
