.modal {

  .modal-header {
    background-color: $table-header-dark;

    .modal-title {
      color: $white;
    }

    .close {
      color: $themecolor-dark;
      opacity: 1;
      text-shadow: none;
    }
  }
}
