.standard-table {
  .table td, .table th {
    text-align: center;
    padding: 5px 0 5px 0;
    font-size: 1rem !important;
    vertical-align: middle;
  }

  .table .thead-dark th {
    background-color: $bg-dark;
    color: $white!important;
    font-weight: $font-weight-medium!important;
  }

  .table tr:nth-child(even) {
    background-color: #eaeaea;
  }

  .table tr > td.name {
    width: 20%;
  }
}
