.dark-cell {
  background-color: #2f2a2a;
  color: white;
  font-weight: 500;
  padding: 10px 10px 10px 10px;
}

.error-message {
  display: none;
  color: #fb3a3a;
}

.header-sort-link {
  color: #FFFFFF;
}

.active-sort {
  color: #fdb714;
}

.pagination-button {
  font-size: 20px;
}

.form-section-title {
  font-weight: bold;
  color: #333;
  width: 100%;
  border: none;
  background-color: transparent;
}

.form-indented-input + p.help-text {
  margin-left: 50px !important;
}

label:has(+ input[type="checkbox"].form-indented-input) {
  margin-left: 20px;
}

.select-label {
  margin-right: 5px;
}

.select2-selection__rendered {
  padding-left: 0!important;
}

.select2-selection__arrow {
  margin-top: 5px;
}

.select2-selection--single {
  border: 1px solid #ced4da!important;
}

.select2-search__field {
  border-radius: 0.25rem;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.collapsable-clickable {
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.centered-container {
  display: flex;
  justify-content: center;
}

.global-container {
    background-color: #f9f8f8;
    padding: 10px;
    height: 100%;
}

.halifax-text {
    display: inline-block;
    color: #2C4373;
}

hr.orange-splitter {
    border-color: #fdb714;
    border-width: 2px;
}

.standard-text {
  color: black;
  font-weight: 400;
}

.standard-input {
  border: 1px solid #ccc!important;
  padding: 5px;
}

.dropdown-toggle {
    min-height: 37px;
}