form {

  label {
    color: $muted;
  }

  .form-control-feedback {

      .errorlist {
          margin: 0;
          padding: 0;

          li {
              font-size: 0.85em;
              color: $danger;
          }
      }
  }
}

.page-wrapper.small {

  .form-control {
    font-size: 0.85rem;
  }
}

.modal form {

  .form-group {
    margin-bottom: 15px;
  }

  .formset {
    label {
      margin-top:5px;
    }
  }

  .help-text {
    margin-left: 30px;
    font-size: 0.9rem;
  }
}


.bootstrap-select div.dropdown-menu ul.dropdown-menu li {
  font-size: 0.9rem;

  .dropdown-item {
    &.active,
    &:active {
      background-color: $link;
    }

    .check-mark {
      top: 50%;
      transform: translateY(-50%);

      &:after {
        border-width: 0 .15em .15em 0;
        color: $success;
      }
    }
  }
}
