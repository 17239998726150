.rich-text-container .icon.check,
.rich-text-container .icon.cancel {
    top: 64px;
}

.ql-container {
    height: auto!important;
    font-size: 16px!important;
}

.ql-editor {
    height: auto!important;
    font-family: Inter, sans-serif;
    font-weight: normal;
    color: black;
}

.input-container {
    position: relative;
    width: 100%;
}

.input-container input,
.input-container textarea {
    border: none;
    padding: 12px 75px 12px 15px; /* Right padding for the buttons */
    width: 100%;
}

.input-container .editor-container {
    padding-right: 70px;
}

.input-container .editor-container:not(.editing) {
    border: none!important;
}

.input-container .editor-container.editing {
    border-width: 0 1px 1px 1px!important;
    border-color: #ccc;
    border-style: solid;
    min-width: 530px;
}

.input-container:hover .editor-container:not(.editing),
.input-container:hover input,
.input-container:hover textarea,
.input-container input.editing:not(.is-invalid) {
    border: 1px solid #ccc!important;
}

.input-container input.is-invalid {
    border: 2px solid #dc3545!important;
}

.input-container .icon {
    position: absolute;
    height: 28px;
    top: 50%;
    transform: translateY(-50%);
    display: none; /* Hide the icons initially */
}

.input-container .icon.edit {
    right: 8px;
    align-items: center;
}

.input-container .icon.check {
    right: 40px;
}

.input-container .icon.cancel {
    right: 8px;
}

.input-container:hover .icon.edit {
    display: flex; /* Show the edit icon */
}

textarea {
    display: block;
}