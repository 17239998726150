.account {

    #wrapper {
        position: relative;
        height: 100vh;

        .account-box {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);

            #logo {
                max-width: 210px;
            }

            .card {
                border: none;
                border-radius: 0;
            }
        }
    }

    .toast {
        box-shadow: 0 0 12px #111;    // Use a dark color for the shadow to fit the dark image in the background of the page.
    }
}
