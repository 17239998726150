
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.css-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
}
.css-bar:after,
.css-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: light;
  color: #a1a2a3;
}
.css-bar:after {
  content: attr(data-label);
  background-color: $white;
  font-size: 0.9rem;
  z-index: 19; /* With a value over 19, the percentages circles in a market segment appears over the menu on the left when we open it */
  overflow: hidden;
}
.css-bar > img {
  z-index: 102;
}
.css-bar:after,
.css-bar > img {
  width: 70px;
  height: 70px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 70px;
}

// Default color
.css-bar.css-bar-0 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(90deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-5 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(108deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-10 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(126deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-15 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(144deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-20 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(162deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-25 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(180deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-30 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(198deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-35 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(216deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-40 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(234deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-45 {
  background-image: linear-gradient(90deg, $white 50%, transparent 50%, transparent), linear-gradient(252deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-50 {
  background-image: linear-gradient(270deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-55 {
  background-image: linear-gradient(288deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-60 {
  background-image: linear-gradient(306deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-65 {
  background-image: linear-gradient(324deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-70 {
  background-image: linear-gradient(342deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-75 {
  background-image: linear-gradient(360deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-80 {
  background-image: linear-gradient(378deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-85 {
  background-image: linear-gradient(396deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-90 {
  background-image: linear-gradient(414deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-95 {
  background-image: linear-gradient(432deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}
.css-bar.css-bar-100 {
  background-image: linear-gradient(450deg, $themecolor-xdark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-xdark 50%, $white 50%, $white);
}

// Primary color
.css-bar-primary.css-bar-0 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(90deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-5 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(108deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-10 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(126deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-15 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(144deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-20 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(162deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-25 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(180deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-30 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(198deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-35 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(216deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-40 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(234deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-45 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(252deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-50 {
  background-image: linear-gradient(270deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-55 {
  background-image: linear-gradient(288deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-60 {
  background-image: linear-gradient(306deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-65 {
  background-image: linear-gradient(324deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-70 {
  background-image: linear-gradient(342deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-75 {
  background-image: linear-gradient(360deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-80 {
  background-image: linear-gradient(378deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-85 {
  background-image: linear-gradient(396deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-90 {
  background-image: linear-gradient(414deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-95 {
  background-image: linear-gradient(432deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-primary.css-bar-100 {
  background-image: linear-gradient(450deg, $themecolor-dark 50%, transparent 50%, transparent), linear-gradient(270deg, $themecolor-dark 50%, $themecolor-xlight 50%, $themecolor-xlight);
}

// Negative color
.chart-negative.css-bar-0 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(90deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-5 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(108deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-10 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(126deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-15 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(144deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-20 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(162deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-25 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(180deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-30 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(198deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-35 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(216deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-40 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(234deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-45 {
  background-image: linear-gradient(90deg, $light-danger 50%, transparent 50%, transparent), linear-gradient(252deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-50 {
  background-image: linear-gradient(270deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-55 {
  background-image: linear-gradient(288deg,$danger 50%,transparent 0,transparent),linear-gradient(270deg,$danger 50%,$light-danger 0,$light-danger);
}
.chart-negative.css-bar-60 {
  background-image: linear-gradient(306deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-65 {
  background-image: linear-gradient(324deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-70 {
  background-image: linear-gradient(342deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-75 {
  background-image: linear-gradient(360deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-80 {
  background-image: linear-gradient(378deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-85 {
  background-image: linear-gradient(396deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-90 {
  background-image: linear-gradient(414deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-95 {
  background-image: linear-gradient(432deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}
.chart-negative.css-bar-100 {
  background-image: linear-gradient(450deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, $light-danger 50%, $light-danger);
}

// Primary color
.css-bar-adaptative.css-bar-0 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(90deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-5 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(108deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-10 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(126deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-15 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(144deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-20 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(162deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-25 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(180deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-30 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(198deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-35 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(216deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-40 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(234deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-45 {
  background-image: linear-gradient(90deg, $themecolor-xlight 50%, transparent 50%, transparent), linear-gradient(252deg, #dc3545 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-50 {
  background-image: linear-gradient(270deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-55 {
  background-image: linear-gradient(288deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-60 {
  background-image: linear-gradient(306deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-65 {
  background-image: linear-gradient(324deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-70 {
  background-image: linear-gradient(342deg, #ffc107 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc107 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-75 {
  background-image: linear-gradient(360deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-80 {
  background-image: linear-gradient(378deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-85 {
  background-image: linear-gradient(396deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-90 {
  background-image: linear-gradient(414deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-95 {
  background-image: linear-gradient(432deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}
.css-bar-adaptative.css-bar-100 {
  background-image: linear-gradient(450deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, $themecolor-xlight 50%, $themecolor-xlight);
}

.css-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
  position: relative;
}
.css-bar-lg:after,
.css-bar-lg > img {
  width: 90px;
  height: 90px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 90px;
}
.css-bar-lg > img {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.css-bar > i {
  width: 70px;
  height: 70px;
  background: #fff;
  line-height: 70px;
  position: absolute;
  border-radius: 100%;
  text-align: center;
  margin-left: 5px;
  margin-top:5px;
  left: 0px;
  right: 0px;
  z-index: 120;
    font-size:27px;
    color:#a6b7bf;
}
.css-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}
.css-bar-sm:after,
.css-bar-sm > img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  line-height: 40px;
}

.css-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}
.css-bar-xs:after,
.css-bar-xs > img {
  width: 24px;
  height: 24px;
  margin-left: 3px;
  margin-top: 3px;
  line-height: 21px;
}

.css-bar {
  background-clip: content-box;
}
