.chart-legend {
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  width: 100%;

  button {
    display: flex;
    border: 1px solid;
    font-size: 0.9rem;
    font-weight: 400;
    background-color: transparent;
    padding: 0 5px 0 0;
    margin: 0;
    text-align: left;

    & + button {
      margin-left: 15px;
    }

    &.client-category-a {
      color: $data-category-a-color;

      &.active {
        background-color: $data-category-a-color;
      }

      & > span {
        background-color: $data-category-a-color;
      }
    }

    &.client-category-b {
      color: $data-category-b-color;

      &.active {
        background-color: $data-category-b-color;
      }

      & > span {
        background-color: $data-category-b-color;
      }
    }

    &.client-category-c {
      color: $data-category-c-color;

      &.active {
        background-color: $data-category-c-color;
      }

      & > span {
        background-color: $data-category-c-color;
      }
    }

    &.client-category-all {
      border: none;
      color: $link;
      font-weight: $font-weight-light;

      &.active {
        visibility: hidden;
      }
    }

    & > span {
      display: inline-block;
      width: 1.5em;
      line-height: 1.5em;
      margin-right: 5px;
      text-align: center;
      border-right: 1px solid $white;
      color: $white;
    }

    &.active {
      color: $white;
    }
  }
}


.sales-distribution-by-client-category {
    font-size: 0.8rem;

    @media #{$small-only} {
        font-size: 1.2rem;
    }
    @media #{$medium-only} {
        font-size: 0.7rem;
    }
    @media #{$large-only} {
        font-size: 0.85rem;
    }
    @media #{$extra-extra-large-and-up} {
        font-size: 1vw;
    }
    @at-root {
        .mini-sidebar .sales-distribution-by-client-category {
            @media #{$extra-large-only} {
                font-size: 0.95rem;
            }
        }
    }

    .client-category {
        position: relative;
        margin-top: 1em;

        .client-category-inner {
            display: flex;
            justify-content: space-between;

            .client-category-title {
                display: inline-block;
                color: white;
                text-align: center;
                width: 1.5em;
                height: 1.5em;
                margin: 0;
                line-height: 1.5em;
                font-size: 1.3em;    // The element size is relative to this value.
            }

            .sales-data {
                text-align: right;
            }

            .clients-data {
                text-align: left;
            }

            .sales-data, .clients-data {
                width: 45%;

                @media #{$extra-extra-large-and-up} {
                    width: 44%;
                }

                @media #{$extra-large-and-up} {

                    @at-root {
                        .mini-sidebar .sales-distribution-by-client-category .client-category .sales-data,
                        .mini-sidebar .sales-distribution-by-client-category .client-category .clients-data {
                            width: 43% !important;
                        }
                    }
                }
            }

            .data-percent, .data-value {
                line-height: 1;
                font-size: 0.9em;
            }

            .data-percent {
                font-weight: $font-weight-medium;
            }

            .progress {
                border-radius: 0;
                height: 8px;
            }
        }

        .average-data {
            position: relative;
            margin-top: 0.5em;
            min-height: 1em;
            text-align: center;
            font-size: 0.8em;
            font-weight: $font-weight-normal;

            &:before {
                content: " ";
                position: absolute;
                top: 55%;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px solid #CCC;
            }

            .average-data-inner {
                display: inline-block;
                background-color: $white;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 8px;
                color: $data-alternate-color;

                .average-symbol {
                    width: 0.8em;
                    vertical-align: baseline;

                    path {
                        fill: $data-alternate-color;
                    }
                }
            }
        }
    }

    #client-category-a {

        .progress-bar, .client-category-title {
            background-color: $data-category-a-color;
        }

        .average-data:before {
            border-top-color: $data-category-a-color;
        }
    }

    #client-category-b {

        .progress-bar, .client-category-title {
            background-color: $data-category-b-color;
        }

        .average-data:before {
            border-top-color: $data-category-b-color;
        }
    }

    #client-category-c {

        .progress-bar, .client-category-title {
            background-color: $data-category-c-color;
        }

        .average-data:before {
            border-top-color: $data-category-c-color;
        }
    }
}
