#toast-container {
    position: fixed;
    top: 10px;
    z-index: 999999;
    pointer-events: none;
    max-width: 350px;

    &.toast-center {
        right: 0;
        left: 0;
        margin: 0 auto;
    }
}

.toast {
    pointer-events: auto;
    padding: 15px 15px 15px 50px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 12px #999999;
    color: $white;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &.showing, &.show {
        opacity: .75;
    }

    &:before {
        content: "\F028";
        display: inline-block;
        position: absolute;
        left: 15px;
        top: 13px;
        font: normal normal normal 24px/1 "Material Design Icons";
    }

    .toast-title {
        font-weight: $font-weight-medium;
        font-size: 0.9rem;

        p {
            margin-bottom: 5px;
            width: 90%;
            display: inline-block;
        }
    }

    .toast-body {
        padding: 0 15px 0 0;
        /*margin-top: -5px;*/
        line-height: 1.3;
        font-weight: 400;
        font-size: 0.85rem;
    }
}

.toast-error {
    background-color: $danger;

    &:before {
        content: "\F029";
    }
}
.toast-info {
    background-color: $info;

    &:before {
        content: "\F028";
    }
}
.toast-success {
    background-color: $success;

    &:before {
        content: "\F5E0";
    }
}
.toast-warning {
    background-color: $warning;

    &:before {
        content: "\F026";
    }
}
