/*
This stylesheet overwrites some CSS rules contained in vendors/material-pro/material/scss/_sidebar.scss.
*/

.left-sidebar {

  .sidebar-nav ul li {

    a {
      font-size: 0.8rem;
      padding-left: 10px;
      transition: none;

      & > i {
        float: left;

        & + span {
          display: inline-block;
          white-space: normal;
          vertical-align: sub;
          line-height: 1.4;

          @at-root {
            .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
              width: 300px;
            }
            .mini-sidebar .left-sidebar .sidebar-nav ul li a > i + span.hide-menu {
              display: none;
            }
          }
        }
      }
    }

    & > ul + ul {
      padding-top:0;
    }

    &.nav-devider {
      background: lighten($black, 20%);
    }

    @at-root {
      .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
        width: 240px;
      }
    }
  }
}
