.btn {

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: $themecolor-dark;
  }
}

.btn-icon {
  padding: 0;
  width: 1.5em;
}

.btn-search-bar {
  font-size: 1.2em;
  padding: 4px 10px;
  margin-left: -1px;
  z-index: 2;
}
